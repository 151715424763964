import React from "react";
import Header from "../header/Header";
import "./Layout.scss";
import MyFooter from "../footer/MyFooter";
const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Header />
      <main className="main-content">{children}</main>
      <MyFooter />
    </div>
  );
};

export default Layout;
