import React from "react";
// import "./SkillTrend.scss";
import dropup from "../../../../src/assets/images/dropup.svg"
import dropdown from "../../../../src/assets/images/dropdown.svg"

const PercentageChip = ({ backgroundColor = false, percentage, increasing = false }) => {
    return (

        <div className="d-flex align-item-center  chipBg" style={{ backgroundColor: increasing ? backgroundColor ? "#EAEAEA" : "#FFF" : "#DC3545", gap: "1px", maxHeight: "32px", padding: "4px 8px" }}>
            <img src={increasing ? dropup : dropdown} alt="dropup" />
            <div className="upPercent" style={{ color: increasing ? "#28A745" : "#fff", fontFamily: "inherit" }}>{percentage}</div>
        </div>
    );
};

export default PercentageChip;

