import React, { createContext, useContext, useState } from "react";

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [countryOrCityDropDown, setCountryOrCityDropDown] = useState("");
  const [countryDetailsDropDown, setCountryDetailsDropDown] = useState("");
  const [isCountryLevel, setIsCountryLevel] = useState(true);
  const [jobDetailsOptions, setJobDetailsOptions] = useState(null);
  const [selectedCountryObject, setSelectedCountryObjects] = useState();

  return (
    <GlobalStateContext.Provider
      value={{
        countryOrCityDropDown,
        setCountryOrCityDropDown,
        countryDetailsDropDown,
        setCountryDetailsDropDown,
        isCountryLevel,
        setIsCountryLevel,
        jobDetailsOptions,
        setJobDetailsOptions,
        selectedCountryObject,
        setSelectedCountryObjects,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalStateContext);
