import Layout from "./components/Layout/Layout";
import "./App.scss";
import Analytics from "./pages/Analytics/Analytics";
import { GlobalStateProvider } from "./GlobalStateContext";

function App() {
  return (
    <div className="app">
      <GlobalStateProvider>
        <Layout>
          <Analytics />
        </Layout>
      </GlobalStateProvider>
    </div>
  );
}

export default App;
