import React, { useState } from "react";
import { WebsiteLogo } from "../svg/WebsiteLogo";
import Button from "../Button/Button.jsx";
import RightChevron from "../svg/RightChevron.jsx";
import "./Header.scss";
import { pageNavigationData } from "../../data/pageNavigationData.js";
import Overview from "../Analytics/Overview/Overview.jsx";
import { useGlobalState } from "../../GlobalStateContext.jsx";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [expandedItem, setExpandedItem] = useState(null);
  const navList = pageNavigationData;
  const {
    setCountryOrCityDropDown,
    setCountryDetailsDropDown,
    setIsCountryLevel,
    setJobDetailsOptions,
    setSelectedCountryObjects,
  } = useGlobalState();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSubmenu = (itemTitle) => {
    setExpandedItem((prevItem) => (prevItem === itemTitle ? null : itemTitle));
  };

  return (
    <header className="header-wrapper">
      <div className="header">
        <div className="header-website-logo">
          <WebsiteLogo />
        </div>

        <div className="header-menu-toggle" onClick={toggleMenu}>
          {isMenuOpen ? "✕" : "☰"}
        </div>
        <nav className={`header-nav ${isMenuOpen ? "open" : ""}`}>
          <ul className="header-nav-list m-0">
            {navList?.map((item) => (
              <li
                className={`header-nav-list-item ${
                  item.active ? "active" : ""
                } ${item.title === "Analytics" ? "has-submenu" : ""}`}
                key={item.id}
              >
                {item.title === "Analytics" ? (
                  <>
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleSubmenu("Analytics");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {item.title}
                      <span
                        className={`submenu-arrow ${
                          expandedItem === "Analytics" ? "expanded" : ""
                        }`}
                      >
                        ▼
                      </span>
                    </span>
                    <div
                      className={`submenu  ${
                        expandedItem === "Analytics" ? "expanded" : ""
                      }`}
                    >
                      <Overview
                        setCountryOrCityDropDown={setCountryOrCityDropDown}
                        setCountryDetailsDropDown={setCountryDetailsDropDown}
                        setIsCountryLevel={setIsCountryLevel}
                        setJobDetailsOptions={setJobDetailsOptions}
                        setSelectedCountryObjects={setSelectedCountryObjects}
                      />
                    </div>
                  </>
                ) : (
                  <a style={{ textDecoration: "none" }} href={item.link}>
                    {item.title}
                  </a>
                )}
              </li>
            ))}
            <li className="d-flex justify-content-center align-items-center">
              <div className="header-action2">
                <Button variant="primary" size="medium">
                  Stay updated <RightChevron />
                </Button>
              </div>
            </li>
          </ul>
        </nav>
        <div className="header-action">
          <Button variant="primary" size="medium">
            Stay updated <RightChevron />
          </Button>
        </div>
      </div>
    </header>
  );
};

export default Header;
