import React, { useEffect, useState } from "react";
import "./SkillVsJobType.scss";
import Dropdown from "../../Dropdown/Dropdown";
import WorningIcon from "../../svg/WorningIcon";
import Sankey, {
  Link,
  Node,
  Margin,
  HoverStyle,
  Hatching,
  Label,
} from "devextreme-react/sankey";
import Tooltip from "../../Tooltip/Tooltip";
import { InfoIcon } from "../../svg/InfoIcon";
import useTooltip from "../../../hooks/useTooltip";
import { isMobile } from "react-device-detect";

const SkillVsJobType = ({
  jobDetailsOptions,
  countryDetailsDropDown,
  isCountryLevel,
}) => {
  const [selectedItem, setSelecetedItem] = useState("");
  const { isVisible, position, showTooltip, hideTooltip } = useTooltip();
  const [sankeyData, setSankeyData] = useState(null);

  const handleValueChanged = (e) => {
    setSelecetedItem(e.value);
  };

  const alignmentValues = ["center", "center", "center"];
  const customizeLinkTooltip = (info) => ({
    html: `<b>From:</b> ${info.source}<br/><b>To:</b> ${info.target}<br/><b>Weight:</b> ${info.weight}`,
  });

  const hoverEffected = (e) => {
    if (e.target.isHovered()) {
      e.target.options.opacity = 1;
      e.target.options.hoverStyle.hatching.opacity = 0;
    } else {
      e.target.options.opacity = 1;
      e.target.options.hoverStyle.hatching.opacity = 0;
    }
  };

  const onNodeHover = (e) => {
    if (e.target.linksIn.length && !e.target.linksOut.length) {
      const indices = e.target.linksIn.map((link) => link.index);
      const indicesNames = e.target.linksIn.map((link) => link.node);
      const selector = indices
        .map((index) => `.link[data-link-idx="${index}"]`)
        .join(", ");
      const selectorName = indicesNames
        .map((node) => `.link[_name="${node}"]`)
        .join(", ");
      const elements = document.querySelectorAll(selector);
      const elementsByNameAttr = document.querySelectorAll(selectorName);
      if (e.target.isHovered()) {
        elements.forEach((element) => {
          element.classList.add("hello");
        });
        elementsByNameAttr.forEach((element) => { });
      } else {
        elements.forEach((element) => {
          element.classList.remove("hello");
        });
      }
    }
  };

  const fetchJobsPerCountry = async () => {
    if (selectedItem !== "All ECSF Roles" && selectedItem !== "") {
      try {
        const response = await fetch(
          `https://cyberhubs.rhibhus.co.uk/api/sankey_data/?level=${isCountryLevel ? "country" : "city"
          }&location=all&job_title=${selectedItem === "All ECSF Roles" ||
            selectedItem === null ||
            selectedItem === ""
            ? "all"
            : selectedItem?.replace("&", "%26")
          }`,
          {
            // https://cyberhubs.rhibhus.co.uk/api/sankey_data/?level=country&location=all&job_title=all
            method: "GET",
          }
        );
        const respon = await response?.json();
        let processed;
        if (respon) {
          processed = respon.map((item) => ({
            ...item,
            weight: item.weight === 0 ? 1 : item.weight,
          }));
        }
        setSankeyData(processed);
      } catch (error) {
        console.log(error);
      }
    } else {
      setSankeyData(null);
    }
  };

  useEffect(() => {
    fetchJobsPerCountry();
  }, [selectedItem, countryDetailsDropDown]);

  return (
    <div className="skillVsJobType" style={isMobile ? { width: "400px" } : {}}>
      <div className="skillVsJobType-header row">
        <h3 className="skillVsJobType-header-title col-12 col-md-6 ">
          Top Skills vs Job Type
          <Tooltip
            content={
              <>
                {/* <p className="title">What does ECSF mean?</p> */}
                <p className="desc">
                  This Sankey chart visualises the relationship between ECSF job
                  titles, skill categories, and specific skills. The chart flows
                  from left to right, starting with ECSF titles, moving through
                  skill categories, and ending with individual skills. The
                  thickness of each line is proportional to the number of skills
                  represented, providing a clear visual representation of the
                  distribution and importance of various skills across different
                  ECSF titles and categories.
                </p>
              </>
            }
            isVisible={isVisible}
            position={"right"}
            size={"large"}
          >
            <div className="jobs-per-country-header-title-icon">
              <InfoIcon></InfoIcon>
            </div>
          </Tooltip>
        </h3>
        <div className="skillVsJobType-header-filters col-12 col-md-6 d-flex  justify-content-lg-end align-items-center justify-content-sm-start">
          <Dropdown
            dropdownList={jobDetailsOptions}
            onValueChanged={handleValueChanged}
          />
        </div>
      </div>
      <div
        className="skillVsJobType-content"
        style={isMobile ? { overflowX: "scroll", width: "350px" } : {}}
      >
        <div
          className="skillVsJobType-content-labels"
          style={
            isMobile && selectedItem !== "All ECSF Roles" && selectedItem !== ""
              ? { width: 800 }
              : {}
          }
        >
          <p>ECSF Role Title</p>
          <p>Skills Categories</p>
          <p>Top Skills</p>
        </div>
        {selectedItem === "All ECSF Roles" || selectedItem === "" ? (
          <div className="skillVsJobType-content-info">
            <WorningIcon />
            <p className="skillVsJobType-content-info-title">
              Select a ECSF Job Role
            </p>
            <p className="skillVsJobType-content-info-desc">
              Select a job role to see relevant skills data.
            </p>
          </div>
        ) : (
          <>
            {sankeyData && (
              <Sankey
                id="sankey"
                dataSource={sankeyData}
                sourceField="target"
                targetField="source"
                weightField="weight"
                height={450}
                width={!isMobile ? "100%" : 800}
                alignment={alignmentValues}
                palette={["#2B00E5", "#FD544F", "#732EC3", "#52E0B4"]}
                // theme="generic.softblue"
                paletteExtensionMode="blend"
                onNodeHoverChanged={onNodeHover}
                onLinkHoverChanged={hoverEffected}
              >
                <Tooltip
                  enabled={true}
                  customizeLinkTooltip={customizeLinkTooltip}
                ></Tooltip>
                <Label useNodeColors={true} />
                <Link className="my-link" colorMode="gradient" opacity={0.3}>
                  <HoverStyle opacity={1}>
                    <Hatching step={10} opacity={0} direction="none" />
                  </HoverStyle>
                </Link>
                <Node width={9} padding={25}></Node>
                <Margin top={20} bottom={20} left={0} right={0} />
              </Sankey>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SkillVsJobType;
