import React, { useRef, useState } from "react";
import "./Geographical.scss";
import VectorMap, {
  Layer,
  Tooltip,
  Source,
  Label,
  Legend,
  LoadingIndicator,
  ControlBar,
} from "devextreme-react/vector-map";
import * as mapsData from "devextreme-dist/js/vectormap-data/europe.js";
import belgium from "../../../assets/images/belgium.svg";
import ModalCancel from "../../../assets/images/ModalCancel.svg";
import { useGlobalState } from "../../../GlobalStateContext";

const Geographical = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [loading, setLoading] = useState(false);
  const { countryOrCityDropDown, isCountryLevel } = useGlobalState();

  const bounds = [-180, 85, 180, -60];

  const fetchCityData = async (country) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://cyberhubs.rhibhus.co.uk/api/geographic_data/?level=city`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      return data.filter((item) => item.country === country) || []; // Filter results by country
    } catch (error) {
      console.error("Error fetching city data:", error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const customizeTooltip = (arg) => {
    if (arg.layer.type === "marker") {
      const { text, value, city } = arg.attribute();
      return {
        text: city
          ? `City: ${city}\nCountry: ${text}\nJobs: ${value}`
          : `Country: ${text}\nJobs: ${value}`,
      };
    }
    return null;
  };

  const customizeText = (arg) =>
    ["< 8000K", "8000K to 10000K", "> 10000K"][arg.index];

  const customizeItems = (items) => items.reverse();

  const vectorMapRef = useRef(null);

  const markerClick = async (e) => {
    if (e.target?.layer.type === "marker") {
      const markerData = e.target.attribute();
      const { city, text } = markerData;

      if (city) {
        // City data is available in the marker
        setSelectedMarker([markerData]);
        setModalVisible(true);
      } else {
        // Fetch city data
        const cityData = await fetchCityData(text);
        const transformedData = cityData.map((item) => ({
          text: item.country,
          city: item.city,
          value: item.job_count,
        }));

        setSelectedMarker(transformedData);
        setModalVisible(true);
      }

      e.component.center(e.target.coordinates());
      e.component.zoomFactor(20);
    }
  };

  const markers = {
    type: "FeatureCollection",
    features: countryOrCityDropDown.map((data) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: data?.coordinates,
      },
      properties: {
        text: data.country,
        city: data?.city,
        value: data.job_count,
        url: data?.imgUrl,
      },
    })),
  };

  const normalCities =
    selectedMarker &&
    selectedMarker.filter((data) => data.city.toLowerCase() !== "remote");
  const remoteCities =
    selectedMarker &&
    selectedMarker.filter((data) => data.city.toLowerCase() === "remote");

  return (
    <div className="geographical-area">
      <h3 className="geographical-area-title">
        {isCountryLevel
          ? "Number of Cybersecurity Job Posts per Country"
          : "Number of Cybersecurity Job Posts per City/Region"}
      </h3>
      <div className="geographical-area-map">
        <VectorMap
          ref={vectorMapRef}
          id="vector-map"
          onClick={markerClick}
          bounds={bounds}
          height={533}
          width={"auto"}
          zoomFactor={7}
          center={[19.9318, 47.1053]}
          onDrawn={(e) => {
            e.component.zoomFactor(6);
            e.component.center([19.9318, 47.1053]);
          }}
        >
          <Layer
            dataSource={mapsData.europe}
            color="#C4C4C4"
            hoverEnabled={false}
          ></Layer>
          <Layer
            dataSource={markers}
            type="marker"
            elementType="image"
            dataField="url"
            size={31}
          >
            <Label enabled={false}></Label>
          </Layer>
          <Tooltip enabled={true} customizeTooltip={customizeTooltip}></Tooltip>
          <Legend
            markerShape="circle"
            customizeItems={customizeItems}
            customizeText={customizeText}
          >
            <Source layer="bubbles" grouping="size"></Source>
          </Legend>
          <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
          <LoadingIndicator show={true} />
          <ControlBar zoomVisible={false} panVisible={false} />
        </VectorMap>
      </div>

      {/* Modal Implementation */}
      {/* {modalVisible && (
        <div className="modal-overlay" onClick={() => setModalVisible(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                <div className="modal-heading-div"><span className="modal-heading">Total number of Jobs in {selectedMarker[0]?.text}</span><button onClick={() => setModalVisible(false)}><img src={ModalCancel} alt=""/></button></div>
                <hr className="modal-heading-hr"/>&nbsp;
                <ul className="modal-ul">
                  {selectedMarker.map((data, index) => (
                    <li key={index} className="modal-ul-li">
                      <span className="modal-city-name">{data.city}</span>
                      <span className="modal-job-count">{data.value}</span>
                    </li>
                  ))}
                </ul>
                
              </>
            )}
          </div>
        </div>
      )} */}

      {modalVisible && (
        <div className="modal-overlay" onClick={() => setModalVisible(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                <div className="modal-heading-div">
                  <span className="modal-heading">
                    Total number of Jobs in {selectedMarker[0]?.text}
                  </span>
                  <button onClick={() => setModalVisible(false)}>
                    <img src={ModalCancel} alt="" />
                  </button>
                </div>
                <hr className="modal-heading-hr" />
                &nbsp;
                <div className="geo-modal-body">
                  <ul className="modal-ul">
                    {normalCities.map((data, index) => (
                      <li key={index} className="modal-ul-li">
                        <span className="modal-city-name">{data.city}</span>
                        <span className="modal-job-count">{data.value}</span>
                      </li>
                    ))}
                  </ul>
                  {remoteCities.length > 0 && (
                    <>
                      <hr className="modal-heading-hr" />
                      <ul className="modal-ul">
                        {remoteCities.map((data, index) => (
                          <li key={index} className="modal-ul-li">
                            <span className="modal-city-name">{data.city}</span>
                            <span className="modal-job-count">
                              {data.value}
                            </span>
                          </li>
                        ))}
                      </ul>
                      <p className="modal-remote-para">
                        Remote work refers to job opportunities advertised in
                        this specific country and that allow professionals to
                        work from any location within the country.
                      </p>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Geographical;
