import React, { useEffect, useState } from "react";
import "./MappedJobsPerCountryChart.scss";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Label,
  Format,
  Legend,
  Export,
  ArgumentAxis,
  Tick,
  ValueAxis,
} from "devextreme-react/chart";
import { SeriesColors } from "../../../../data/barChartGradients";
import { isMobile, isTablet, isDesktop } from "react-device-detect";

const MappedJobsPerCountryChart = ({
  countryDetailsDropDown,
  selectedCountry,
  selectedJob,
  isCountryLevel,
  selectedCountryObject,
}) => {
  const [selectedSeries, setSelectedSeries] = useState([]);
  const [detailsForGraph, setDetailsForGraph] = useState([]);

  function onPointClick(e) {
    e.target.select();
  }

  function onLegendClick(e) {
    const seriesName = e.target.argument;
    setSelectedSeries((prevSelected) => {
      if (prevSelected.length === 0 || prevSelected.includes(seriesName)) {
        return prevSelected.includes(seriesName) ? [] : [seriesName];
      } else {
        return [...prevSelected, seriesName];
      }
    });
  }

  const fetchJobsPerCountry = async () => {
    const response = await fetch(
      `https://cyberhubs.rhibhus.co.uk/api/job_title_data?level=${
        isCountryLevel
          ? "country"
          : selectedCountry === "All Cities/Regions" ||
            selectedCountry === "All Countries"
          ? "country"
          : "city"
      }&locations=${
        selectedCountry !== null
          ? selectedCountry === "All Countries"
            ? "all"
            : selectedCountry === "All Cities/Regions"
            ? selectedCountryObject.country
            : selectedCountry
          : "all"
      }&job_titles=${
        selectedJob === "All ECSF Roles" || selectedJob === null
          ? "all"
          : selectedJob?.replace("&", "%26")
      }`,
      {
        method: "GET",
      }
    );
    const respon = await response.json();

    const jobTitles = Array.from(
      new Set(
        Object.values(respon).flatMap((stateJobs) => Object.keys(stateJobs))
      )
    );

    const grossProductData = Object.keys(respon).map((state) => {
      const jobData = respon[state];

      const stateData = {
        state,
        ...jobTitles.reduce((acc, title) => {
          if (title in jobData) {
            acc[title] = jobData[title];
          }
          return acc;
        }, {}),
      };

      return stateData;
    });
    let filteredData = grossProductData;

    setDetailsForGraph(filteredData);
  };

  useEffect(() => {
    fetchJobsPerCountry();
  }, [selectedCountry, selectedJob, countryDetailsDropDown]);

  const getSeriesColor = (job) => {
    const colorKey = Object.keys(SeriesColors).find(
      (key) => key.toLowerCase() === job.toLowerCase()
    );
    if (!colorKey) return SeriesColors.darkBlue; // default color

    if (selectedSeries.length === 0 || selectedSeries.includes(job)) {
      return SeriesColors[colorKey];
    } else {
      return SeriesColors[colorKey + "Op"] || SeriesColors[colorKey];
    }
  };

  const customizePoint = (pointInfo) => {
    const color = getSeriesColor(pointInfo.series.name);
    return {
      color: color.base,
      fill: color.fillId,
    };
  };

  return (
    <div
      className="bar-chart "
      style={isMobile ? { overflowX: "scroll", width: "350px" } : {}}
    >
      {detailsForGraph.length > 0 ? (
        <Chart
          id="chart"
          className="pb-2"
          dataSource={detailsForGraph}
          onPointClick={onPointClick}
          height={!isMobile ? 282 : 500}
          barGroupPadding={0.3}
          onLegendClick={onLegendClick}
          width={isMobile ? 1000 : "100%"}
        >
          <CommonSeriesSettings
            argumentField="state"
            type="bar"
            ignoreEmptyPoints={true}
            barPadding={0.2}
          >
            <Label verticalAlignment="top" visible={false}>
              <Format type="fixedPoint" precision={0} />
            </Label>
          </CommonSeriesSettings>
          <ArgumentAxis>
            <Tick visible={false} />
          </ArgumentAxis>
          <ValueAxis visible={true}>
            <Tick visible={true} />
            <Label visible={true} />
          </ValueAxis>
          {Object.keys(detailsForGraph[0] || {})
            .filter((key) => key !== "state")
            .map((job) => (
              <Series
                key={job}
                cornerRadius={6}
                valueField={job}
                name={job}
                customizePoint={customizePoint}
                // color={getSeriesColor(job)}
              />
            ))}
          <Legend
            verticalAlignment="top"
            horizontalAlignment={isMobile || isTablet ? "center" : "left"}
            itemTextPosition="right"
            markerSize={6}
            rowCount={isMobile || isTablet ? undefined : 15}
            columnCount={isMobile || isTablet ? 3 : undefined}
            hoverMode="includePoints"
            customizeItems={(items) => {
              return items.map((item) => ({
                ...item,
                color: getSeriesColor(item.series.name),
              }));
            }}
          />

          <Export enabled={false} />
        </Chart>
      ) : (
        <h4>No data Found</h4>
      )}
    </div>
  );
};

export default MappedJobsPerCountryChart;
