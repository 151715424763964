import React, { useEffect, useState } from "react";

import belgium from "../../../assets/images/belgium.svg";
import estonia from "../../../assets/images/estonia.svg";
import greece from "../../../assets/images/greece.svg";
import hungary from "../../../assets/images/hungary.svg";
import lithuania from "../../../assets/images/lithuania.svg";
import slovenia from "../../../assets/images/slovenia.svg";
import spain from "../../../assets/images/spain.svg";

import "./Overview.scss";

const Overview = ({
  setCountryOrCityDropDown,
  setCountryDetailsDropDown,
  setIsCountryLevel,
  setJobDetailsOptions,
  setSelectedCountryObjects,
  setActiveTab
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isOverviewSelected, setIsOverviewSelected] = useState(true);
  const [countriesList, setCountriesList] = useState();

  const handleCountryClick = async (id) => {
    setActiveTab("anotherTab")
    setSelectedCountryObjects(id);
    setIsCountryLevel(false);
    setSelectedCountry(id.id);
    setIsOverviewSelected(false);
    await fetchJobDropdownDetails(id.country);
    await fetch_geographic_data("city", id);
  };

  const fetch_geographic_data = async (type, filterBasedOn) => {
    try {
      await fetch(
        `https://cyberhubs.rhibhus.co.uk/api/geographic_data/?level=${type}`,
        {
          method: "GET",
          // headers: {
          //     "content-type": "application/json;",
          //     Authorization: "",
          // },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          const imageUrls = {
            Belgium: belgium,
            Estonia: estonia,
            Greece: greece,
            Hungary: hungary,
            Lithuania: lithuania,
            Slovenia: slovenia,
            Spain: spain,
          };
          if (type === "country") {
            const transformedData = data.map((item, index) => ({
              id: index + 1,
              country: item.country,
              coordinates: item.coordinates,
              job_count: item.job_count,
              imgUrl: imageUrls[item.country],
            }));

            const transformedDataCountry = data.map((src) => src.country);
            setCountriesList(transformedData);

            setCountryOrCityDropDown(transformedData);
            setCountryDetailsDropDown([
              "All Countries",
              ...transformedDataCountry,
            ]);
          } else if (type === "city") {
            const filteredCountry = data.filter(
              (item) => item.country === filterBasedOn.country
            );
            const transformedData = filteredCountry.map((item, index) => ({
              id: index + 1,
              country: item.country,
              city: item.city,
              coordinates: item.coordinates,
              job_count: item.job_count,
              imgUrl: imageUrls[item.country],
            }));
            // setCountryOrCityDropDown([filterBasedOn])
            setCountryOrCityDropDown(transformedData);
            const transformedDataCountry = transformedData.map(
              (src) => src.city
            );
            setCountryDetailsDropDown([
              "All Cities/Regions",
              ...transformedDataCountry,
            ]);
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleTitleClick = () => {
    setActiveTab("overview")

    setIsCountryLevel(true);
    setIsOverviewSelected(true);
    setSelectedCountry(null);
    fetch_geographic_data("country");
  };

  const fetchJobDropdownDetails = async (level) => {
    try {
      const response = await fetch(
        `https://cyberhubs.rhibhus.co.uk/api/job_title_dropdown/?level=country&locations=${level}`,
        {
          method: "GET",
        }
      );
      const respon = await response.json();
      setJobDetailsOptions(["All ECSF Roles", ...respon]);
    } catch (error) {
      console.log("fetchJobDropdownDetails", error.message);
    }
  };
  /* eslint-disable */
  useEffect(() => {
    fetchJobDropdownDetails("all");
    isOverviewSelected && fetch_geographic_data("country");
  }, []);

  return (
    <div className="overview">
      <h3
        className={`overview-title ${isOverviewSelected ? "selected" : ""}`}
        onClick={handleTitleClick}
      >
        Overview
      </h3>
      {countriesList && (
        <ul className="overview-list">
          {countriesList.map((item) => (
            <li
              className={`overview-list-item ${selectedCountry === item.id ? "selected" : ""
                }`}
              key={item.id}
              onClick={() => handleCountryClick(item)}
            >
              <span>{item.country}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Overview;
