import React, { useEffect, useState } from "react";
import "./SkillsAnalysisLineChart.scss";
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  CommonAxisSettings,
  Grid,
  Export,
  Legend,
  Margin,
  Tooltip,
  Label,
  Format,
  ValueAxis,
  Tick,
} from "devextreme-react/chart";
import { isMobile, isTablet, isDesktop } from "react-device-detect";

const SkillsAnalysisLineChart = ({
  countryDetailsDropDown,
  selectedCountry,
  selectedJob,
  isCountryLevel,
  selectedCountryObject,
}) => {
  const [sharingStatisticsInfo, setsharingStatisticsInfo] = useState();
  const architectureSources = [
    { value: "raw_count", name: "Raw counts of the skill" },
    { value: "relative_count", name: "Relative counts of the skill" },
  ];

  const fetchRoleTitles = async (selectedCountry, selectedJob) => {
    try {
      await fetch(
        `https://cyberhubs.rhibhus.co.uk/api/skills_analysis/?level=${
          isCountryLevel || selectedCountry === "All Cities/Regions"
            ? "country"
            : "city"
        }&location=${
          selectedCountry !== null
            ? selectedCountry === "All Countries"
              ? "all"
              : selectedCountry === "All Cities/Regions"
              ? selectedCountryObject.country
              : selectedCountry
            : "all"
        }&job_title=${
          selectedJob === "All ECSF Roles" || selectedJob === null
            ? "all"
            : selectedJob?.replace("&", "%26")
        }`,
        {
          method: "GET",
          // headers: {
          //     "content-type": "application/json;",
          //     Authorization: "",
          // },
        }
      )
        .then((response) => response.json())
        .then((respon) => {
          setsharingStatisticsInfo(respon?.data);
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  /* eslint-disable */
  useEffect(() => {
    fetchRoleTitles(selectedCountry, selectedJob);
  }, [selectedCountry, selectedJob, countryDetailsDropDown]);

  return (
    <>
      {sharingStatisticsInfo ? (
        <div
          className="line-chart"
          style={
            isMobile
              ? { position: "relative", overflowX: "scroll", width: "350px" }
              : {}
          }
        >
          <svg
            width="500"
            height="200"
            viewBox="0 0 500 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              visibility: "hidden",
              width: "500px",
              position: "absolute",
            }}
          >
            <path
              d="M2 198C36.6887 193.907 132.921 172.087 159.863 117.061C245.409 -57.6568 272.213 5.42012 293.032 55.6587C307.142 89.7094 336.246 132.69 376.814 128.783C417.382 124.875 434.138 98.0818 454.422 77.4283C474.706 56.7748 530.267 2.6291 588.473 35.0049C657.263 73.2671 621.633 151.111 711.06 151.111C815.126 151.111 758.683 192.418 831 198"
              stroke="url(#paint0_linear_3102_36515)"
              strokeWidth="4"
              strokeMiterlimit="2.61313"
              strokeLinecap="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_3102_36515"
                x1="2"
                y1="0.7866"
                x2="1231"
                y2="0.7866"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.561865" stopColor="#AD00FF" />
                <stop offset="1" stopColor="#EE0000" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="831"
            height="175"
            viewBox="0 0 831 175"
            fill="none"
            style={{
              visibility: "hidden",
              width: "500px",
              position: "absolute",
            }}
          >
            <path
              d="M2.70117 173C37.4292 168.744 119.069 147.578 167.806 96.9647C228.727 33.6987 292.297 68.524 301.126 74.9087C309.956 81.2933 337.326 105.091 377.94 101.028C418.554 96.9647 445.042 69.1045 465.349 47.6288C485.656 26.1532 532.45 -19.7001 590.723 13.9644C648.995 47.6288 618.976 116.699 708.15 121.923C797.325 127.147 755.828 163.713 828.227 169.517"
              stroke="url(#paint0_linear_3102_36514)"
              strokeWidth="4"
              strokeMiterlimit="2.61313"
              strokeLinecap="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_3102_36514"
                x1="730.196"
                y1="-123.9914"
                x2="486.711"
                y2="20.226"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.23475" stopColor="#FFB800" />
                <stop offset="1" stopColor="#37D500" />
              </linearGradient>
            </defs>
          </svg>
          <Chart
            palette="Violet"
            // className={`${isMobile ? "mobileViewLineChart" : ""}`}
            height={!isMobile ? 282 : 350}
            width={!isMobile ? "100%" : 700}
            dataSource={sharingStatisticsInfo}
          >
            <CommonSeriesSettings argumentField="top_skill" type="spline" />
            <CommonAxisSettings>
              <Grid visible={true} />
            </CommonAxisSettings>
            <ValueAxis visible={false}>
              <Label visible={false}>
                <Format type="decimal" />
              </Label>
            </ValueAxis>
            {architectureSources.map((item, index) => (
              <Series
                key={item.value}
                valueField={item.value}
                name={item.name}
                width={4}
              />
            ))}
            <Margin bottom={0} />
            <ArgumentAxis
              valueMarginsEnabled={false}
              allowDecimals={false}
              axisDivisionFactor={60}
            >
              <Grid visible={false} />
              <Tick visible={true} />
              <Label>
                <Format type="decimal" />
              </Label>
            </ArgumentAxis>

            {isMobile ? (
              <Legend
                itemTextPosition="right"
                verticalAlignment="bottom"
                horizontalAlignment="center"
              >
                <Margin right={180} top={30} />
              </Legend>
            ) : isTablet ? (
              <Legend
                itemTextPosition="right"
                verticalAlignment="top"
                horizontalAlignment="center"
              >
                <Margin right={200} top={30} />
              </Legend>
            ) : isDesktop ? (
              <Legend verticalAlignment="bottom" horizontalAlignment="left">
                <Margin right={100} />
              </Legend>
            ) : (
              <Legend verticalAlignment="bottom" horizontalAlignment="left">
                <Margin right={100} />
              </Legend>
            )}
            <Export enabled={false} />
            <Tooltip enabled={true} />
          </Chart>
        </div>
      ) : (
        <h4>No Result</h4>
      )}
    </>
  );
};

export default SkillsAnalysisLineChart;
