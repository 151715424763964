import React, { useEffect, useState } from "react";
import "./SkillTrend.scss";
import Dropdown from "../../Dropdown/Dropdown";
import WorningIcon from "../../svg/WorningIcon";
import Sankey, {
    Link,
    Node,
    Margin,
    HoverStyle,
    Hatching,
    Label,
} from "devextreme-react/sankey";
import Tooltip from "../../Tooltip/Tooltip";
import { InfoIcon } from "../../svg/InfoIcon";
import useTooltip from "../../../hooks/useTooltip";
import { isMobile } from "react-device-detect";
import Badges from "./Badges";
import PercentageChip from "./PercentageChip";

const SkillTrend = ({
    jobDetailsOptions,
    countryDetailsDropDown,
    isCountryLevel,
    selectedCountryObject
}) => {
    const [countryDropDown, setCountryDropDown] = useState(null);
    const [selectedloc, setSelectedloc] = useState(null);
    const [selectedItem, setSelecetedItem] = useState("");
    const [titleBasedOnLocation, setTitleBasedOnLocation] = useState(["All ECSF Roles"]);
    // const [titleBasedOnLocation, setTitleBasedOnLocation] = useState([]);
    const [jobDetailsDropDown, setJobDetailsDropDown] =
        useState(jobDetailsOptions);
    const { isVisible, position, showTooltip, hideTooltip } = useTooltip();
    const [sankeyData, setSankeyData] = useState(null);


    const [emergingSkills, setEmergingSkills] = useState([]);
    const [constantSkills, setConstantSkills] = useState([]);
    const [decliningSkills, setDecliningSkills] = useState([]);


    const handleValueChanged = (e) => {
        setSelecetedItem(e.value);
    };

    const alignmentValues = ["center", "center", "center"];
    const customizeLinkTooltip = (info) => ({
        html: `<b>From:</b> ${info.source}<br/><b>To:</b> ${info.target}<br/><b>Weight:</b> ${info.weight}`,
    });

    const hoverEffected = (e) => {
        if (e.target.isHovered()) {
            e.target.options.opacity = 1;
            e.target.options.hoverStyle.hatching.opacity = 0;
        } else {
            e.target.options.opacity = 1;
            e.target.options.hoverStyle.hatching.opacity = 0;
        }
    };

    const onNodeHover = (e) => {
        if (e.target.linksIn.length && !e.target.linksOut.length) {
            const indices = e.target.linksIn.map((link) => link.index);
            const indicesNames = e.target.linksIn.map((link) => link.node);
            const selector = indices
                .map((index) => `.link[data-link-idx="${index}"]`)
                .join(", ");
            const selectorName = indicesNames
                .map((node) => `.link[_name="${node}"]`)
                .join(", ");
            const elements = document.querySelectorAll(selector);
            const elementsByNameAttr = document.querySelectorAll(selectorName);
            if (e.target.isHovered()) {
                elements.forEach((element) => {
                    element.classList.add("hello");
                });
                elementsByNameAttr.forEach((element) => { });
            } else {
                elements.forEach((element) => {
                    element.classList.remove("hello");
                });
            }
        }
    };


    const fetchSkillTrend = async (e) => {

        const response = await fetch(
            `https://cyberhubs.rhibhus.co.uk/api/skills_trends/?level=${isCountryLevel || e === "All Cities/Regions" ? "country" : "city"}&location=${countryDropDown || "all"}&job_title=${selectedloc || "all"}`,
            {
                method: "GET",
            }
        );

        const respon = await response?.json();
        console.log("100", respon);
        setEmergingSkills(respon?.emerging_skills)
        setConstantSkills(respon?.constant_skills)
        setDecliningSkills(respon?.disappearing_skills)

    };


    const fetchPerticularJobs = async (e) => {
        const response = await fetch(
            `https://cyberhubs.rhibhus.co.uk/api/job_title_dropdown/?level=${isCountryLevel || e === "All Cities/Regions" ? "country" : "city"}&locations=${e != null ? e === "All Countries" ? "all" : e === "All Cities/Regions" ? selectedCountryObject.country : e
                : "all"
            }`,
            {
                method: "GET",
            }
        );
        const respon = await response.json();
        // setJobDetailsDropDown(["All ECSF Roles", ...respon]);
        setTitleBasedOnLocation(["All ECSF Roles", ...respon])
        console.log({ respon });

    };

    const handleCountryDropdownChange = (e) => {
        setCountryDropDown(e.value);
    };
    const handleLocation = (e) => {
        setSelectedloc(e.value);
    };

    useEffect(() => {
        fetchPerticularJobs(countryDropDown);
    }, [countryDropDown]);

    useEffect(() => {
        fetchSkillTrend(selectedloc);
    }, [selectedloc]);


    const dummyData = ["communication", "commdata analysis", "sketchinf"];

    console.log("aa", countryDetailsDropDown);
    console.log("pp", titleBasedOnLocation);
    console.log("constantSkills", constantSkills);


    return (
        <div className="skillVsJobType" style={isMobile ? { width: "400px" } : {}}>
            <div className="skillVsJobType-header row">
                <h3 className="skillVsJobType-header-title col-12 col-md-6 ">
                    Skills Trend

                </h3>
                <div className="skillVsJobType-header-filters col-12 col-md-6 d-flex  justify-content-lg-end align-items-center justify-content-sm-start">
                    <Dropdown
                        dropdownList={countryDetailsDropDown}
                        onValueChanged={handleCountryDropdownChange}
                        key={1}
                    />

                    <Dropdown
                        dropdownList={titleBasedOnLocation}
                        onValueChanged={handleLocation}
                        key={2}
                    />
                </div>


            </div>
            <div
                className="skillVsJobType-content"
                style={isMobile ? { overflowX: "scroll", width: "350px" } : {}}
            >


                <div className='parent'>
                    <div className="head1">
                        <div className="w-100">
                            <p style={{ fontSize: "16px", fontWeight: 600, marginBottom: "4px" }}>Emerging Skills</p>
                            <p className="sub">Defined as a skill that has not seen a change bigger than 10% over the last 6 months.</p>
                            <div className="badge-container w-100">
                                {emergingSkills?.slice(0, 5).map((item, index) => (
                                    <div key={index}>
                                        <Badges type={item.type} skill={item.skill} increasing={item.increasing} percentage={item.percentage} />
                                    </div>
                                ))}
                            </div>
                            {/* 
                            <div className="badge-container w-100" style={{ display: "flex", flexWrap: "wrap", gap: "16px", justifyContent: "space-between" }}>
                                {emergingSkills?.slice(0, 4).map((item, index) => (
                                    <div key={index} style={{ flex: "1 1 calc(25% - 16px)", display: "flex", justifyContent: "center", maxWidth: "calc(25% - 16px)" }}>
                                        <Badges
                                            type={item.type}
                                            skill={item.skill}
                                            increasing={item.increasing}
                                            percentage={item.percentage}
                                        />
                                    </div>
                                ))}
                            </div> */}




                        </div>
                    </div>
                    <div class="head2">
                        <div className="child1">

                            <div style={{ fontSize: "16px", fontWeight: 600 }}>Constant Skills</div>
                            <p className="sub">Defined as a skill that has not seen a change bigger than 10% over the last 6 months.</p>

                            <div style={{ display: "flex", flexDirection: "column", gap: "31px", width: "100%" }}>
                                {constantSkills?.map((item, index) => (
                                    <div key={index}>{item}</div>
                                ))}
                            </div>

                        </div>
                        <div className='child2'>
                            <div style={{ fontSize: "16px", fontWeight: 600, }}>Declining Skills</div>
                            <p className="sub">Defined as a skill that has not seen a change bigger than 10% over the last 6 months.</p>

                            {/* <div style={{ display: "flex", flexDirection: "column", gap: "16px", width: "100%", }}>
                                {decliningSkills?.map((item, index) => (
                                    <div style={{ display: "flex", flexDirection: "row", gap: "4px", justifyContent: "space-between" }}>
                                        <div key={index}>{item.skill}</div>
                                        <PercentageChip percentage={item.percentage} increasing={item.increasing} />
                                    </div>
                                ))}
                            </div> */}



                            <div style={{ display: "flex", flexDirection: "column", gap: "16px", width: "100%" }}>
                                {decliningSkills?.map((item, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "4px",
                                            justifyContent: "space-between",
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <div
                                            style={{
                                                maxWidth: "70%", // Adjust as needed
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                                WebkitBoxOrient: "vertical",
                                                WebkitLineClamp: 2 // Limits text to two lines

                                            }}
                                        >
                                            {item.skill}
                                        </div>
                                        <PercentageChip percentage={item.percentage} increasing={item.increasing} />
                                    </div>
                                ))}
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div >
    );
};

export default SkillTrend;
