import React, { useState } from "react";
import "./Analytics.scss";
import Overview from "../../components/Analytics/Overview/Overview";
import Welcome from "../../components/Analytics/Welcome/Welcome";
import Geographical from "../../components/Analytics/Geographical/Geographical";
import MappedJobsPerCountry from "../../components/Analytics/MappedJobsPerCountry/MappedJobsPerCountry";
import JobAnalysis from "../../components/Analytics/JobAnalysis/JobAnalysis";
import SkillsAnalysisSection from "../../components/Analytics/SkillsAnalysisSection/SkillsAnalysisSection";
import SkillVsJobType from "../../components/Analytics/SkillVsJobType/SkillVsJobType";
import { useGlobalState } from "../../GlobalStateContext";
import SkillTrend from "../../components/Analytics/SkillTrend/SkillTrend";

const Analytics = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const {
    countryOrCityDropDown,
    setCountryOrCityDropDown,
    countryDetailsDropDown,
    setCountryDetailsDropDown,
    isCountryLevel,
    setIsCountryLevel,
    jobDetailsOptions,
    setJobDetailsOptions,
    selectedCountryObject,
    setSelectedCountryObjects,
  } = useGlobalState();


  console.log("Current activeTab:", activeTab);


  return (
    <div className="analytics-page row ">
      <div className="analytics-page-overview col-2 hide">
        <Overview
          setCountryOrCityDropDown={setCountryOrCityDropDown}
          setCountryDetailsDropDown={setCountryDetailsDropDown}
          setIsCountryLevel={setIsCountryLevel}
          setJobDetailsOptions={setJobDetailsOptions}
          setSelectedCountryObjects={setSelectedCountryObjects}
          setActiveTab={setActiveTab} // Pass tab change handler
        />
      </div>
      {/* <======================================> */}
      <div className="analytics-page-cotent col-10 hide">
        <Welcome />
        <div className="analytics-page-cotent-geographical">
          <h3 className="analytics-page-cotent-geographical-title">
            Geographical Analysis
          </h3>
          {countryOrCityDropDown && (
            <Geographical
              countryOrCityDropDown={countryOrCityDropDown}
              isCountryLevel={isCountryLevel}
            />
          )}
          {(countryDetailsDropDown && jobDetailsOptions) && (
            <MappedJobsPerCountry
              countryDetailsDropDown={countryDetailsDropDown}
              isCountryLevel={isCountryLevel}
              jobDetailsOptions={jobDetailsOptions}
              selectedCountryObject={selectedCountryObject}
            />
          )}
          {(countryDetailsDropDown && jobDetailsOptions) && (
            <JobAnalysis
              countryDetailsDropDown={countryDetailsDropDown}
              isCountryLevel={isCountryLevel}
              jobDetailsOptions={jobDetailsOptions}
              selectedCountryObject={selectedCountryObject}
            />
          )}
          {(countryDetailsDropDown && jobDetailsOptions) && (
            <SkillsAnalysisSection
              countryDetailsDropDown={countryDetailsDropDown}
              isCountryLevel={isCountryLevel}
              jobDetailsOptions={jobDetailsOptions}
              selectedCountryObject={selectedCountryObject}
            />
          )}
          {jobDetailsOptions && (
            <SkillVsJobType
              countryDetailsDropDown={countryDetailsDropDown}
              isCountryLevel={isCountryLevel}
              jobDetailsOptions={jobDetailsOptions}
              selectedCountryObject={selectedCountryObject}
            />
          )}

          {/* {jobDetailsOptions && (
            <SkillTrend
              countryDetailsDropDown={countryDetailsDropDown}
              isCountryLevel={isCountryLevel}
              jobDetailsOptions={jobDetailsOptions}
              selectedCountryObject={selectedCountryObject}
            />
          )} */}
          {activeTab === "overview" && jobDetailsOptions && (
            <SkillTrend
              countryDetailsDropDown={countryDetailsDropDown}
              isCountryLevel={isCountryLevel}
              jobDetailsOptions={jobDetailsOptions}
              selectedCountryObject={selectedCountryObject}
            />
          )}


        </div>
      </div>
      {/* <======================================> */}
      <div className="analytics-page-cotent col-12 show overflow-x-hidden">
        <Welcome />
        <div className="row ">
          <div className="analytics-page-cotent-geographical">
            <h3 className="analytics-page-cotent-geographical-title">
              Geographical Analysis
            </h3>
            {countryOrCityDropDown && (
              <Geographical
                countryOrCityDropDown={countryOrCityDropDown}
                isCountryLevel={isCountryLevel}
              />
            )}
            {countryDetailsDropDown && jobDetailsOptions && (
              <MappedJobsPerCountry
                countryDetailsDropDown={countryDetailsDropDown}
                isCountryLevel={isCountryLevel}
                jobDetailsOptions={jobDetailsOptions}
                selectedCountryObject={selectedCountryObject}
              />
            )}
            {countryDetailsDropDown && jobDetailsOptions && (
              <JobAnalysis
                countryDetailsDropDown={countryDetailsDropDown}
                isCountryLevel={isCountryLevel}
                jobDetailsOptions={jobDetailsOptions}
                selectedCountryObject={selectedCountryObject}
              />
            )}
            {countryDetailsDropDown && jobDetailsOptions && (
              <SkillsAnalysisSection
                countryDetailsDropDown={countryDetailsDropDown}
                isCountryLevel={isCountryLevel}
                jobDetailsOptions={jobDetailsOptions}
                selectedCountryObject={selectedCountryObject}
              />
            )}
            {jobDetailsOptions && (
              <SkillVsJobType
                countryDetailsDropDown={countryDetailsDropDown}
                isCountryLevel={isCountryLevel}
                jobDetailsOptions={jobDetailsOptions}
                selectedCountryObject={selectedCountryObject}
              />
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
