import React, { useState, useRef, useEffect } from "react";
import "./Tooltip.scss";
import { isMobile, isTablet } from "react-device-detect";

const Tooltip = ({ children, content, position, size, onClick, footer }) => {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef(null);

  const showTooltip = () => setIsVisible(true);
  const hideTooltip = () => setIsVisible(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        hideTooltip();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="tooltip-container" style={{ position: "relative" }}>
      <div onMouseEnter={showTooltip} onClick={showTooltip}>
        {children}
      </div>
      {isVisible && (
        <div
          ref={tooltipRef}
          className={`tooltip-content ${
            isMobile || isTablet ? "top" : position
          } ${isMobile || isTablet ? "small" : size}`}
          onClick={onClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="41"
            height="36"
            viewBox="0 0 41 36"
            fill="none"
          >
            <path
              d="M24.4129 33.75C22.8733 36.4167 19.0243 36.4167 17.4847 33.75L1.46319 6.00001C-0.0764061 3.33334 1.84809 3.67616e-06 4.92729 3.40697e-06L36.9702 6.05683e-07C40.0494 3.36491e-07 41.9739 3.33333 40.4343 6L24.4129 33.75Z"
              fill="white"
            />
          </svg>
          <div className="tooltip-content-section">
            {content}
            {footer && <div className="tooltip-footer">{footer}</div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
