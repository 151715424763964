import React, { useEffect, useState } from "react";
import { InfoIcon } from "../../svg/InfoIcon";
import "./MappedJobsPerCountry.scss";
import MappedJobsPerCountryChart from "./MappedJobsPerCountryChart/MappedJobsPerCountryChart";
import Dropdown from "../../Dropdown/Dropdown";
import useTooltip from "../../../hooks/useTooltip";
import Tooltip from "../../Tooltip/Tooltip";

const MappedJobsPerCountry = ({
  countryDetailsDropDown,
  isCountryLevel,
  jobDetailsOptions,
  selectedCountryObject,
}) => {
  const { isVisible, position, showTooltip, hideTooltip } = useTooltip();

  const [countryDropDown, setCountryDropDown] = useState("All Countries");
  const [jobDropDown, setJobDropDown] = useState("All ECSF Roles");
  const [jobDetailsDropDown, setJobDetailsDropDown] =
    useState(jobDetailsOptions);

  const fetchPerticularJobs = async (e) => {
    const response = await fetch(
      `https://cyberhubs.rhibhus.co.uk/api/job_title_dropdown/?level=${
        isCountryLevel || e === "All Cities/Regions" ? "country" : "city"
      }&locations=${
        e !== null
          ? e === "All Countries"
            ? "all"
            : e === "All Cities/Regions"
            ? selectedCountryObject.country
            : e
          : "all"
      }`,
      {
        method: "GET",
      }
    );
    const respon = await response?.json();

    console.log({ Naane: respon });
    setJobDetailsDropDown(["All ECSF Roles", ...respon]);
  };
  const handleCountryDropdownChange = (e) => {
    setCountryDropDown(e.value);
  };

  const handleJobDropdownChange = (e) => {
    setJobDropDown(e.value);
  };

  useEffect(() => {
    fetchPerticularJobs(countryDropDown);
  }, [countryDropDown]);

  return (
    <div className="jobs-per-country">
      <div className="jobs-per-country-header row">
        <h3 className="jobs-per-country-header-title col-12 col-md-6">
          ECSF Mapped Jobs per Country
          <Tooltip
            content={
              <>
                <p className="title">What does ECSF mean?</p>
                <p className="desc">
                  The European Cybersecurity Skills Framework (ECSF) provides a
                  practical tool to support the identification and articulation
                  of tasks, competences, skills and knowledge associated with
                  the roles of European cybersecurity professionals. The main
                  purpose of the framework is to create a common understanding
                  between individuals, employers and learning providers across
                  EU Member States, making it a valuable tool to bridge the gap
                  between the cybersecurity professional workplace and learning
                  environments. (ENISA, 2022) Learn more about the ECSF here:
                </p>
              </>
            }
            footer={
              <>
                <a
                  href="https://cyberhubs.eu/resource/european-cybersecurity-skills-framework-the-common-language-for-cybersecurity-professional-workforce-development/"
                  target="_blank"
                >
                  Learn more about the ECSF
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 14"
                    fill="none"
                    style={{ marginLeft: 5 }}
                  >
                    <path
                      d="M12 9.16667V13.1667C12 13.5203 11.8595 13.8594 11.6095 14.1095C11.3594 14.3595 11.0203 14.5 10.6667 14.5H3.33333C2.97971 14.5 2.64057 14.3595 2.39052 14.1095C2.14048 13.8594 2 13.5203 2 13.1667V5.83333C2 5.47971 2.14048 5.14057 2.39052 4.89052C2.64057 4.64048 2.97971 4.5 3.33333 4.5H7.33333"
                      stroke="black"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path d="M10 2.5H14V6.5" fill="#2B00E5" />
                    <path
                      d="M10 2.5H14V6.5"
                      stroke="black"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.66602 9.83333L13.9993 2.5"
                      stroke="black"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </>
            }
            isVisible={isVisible}
            position={"right"}
            size={"large"}
          >
            <div
              className="jobs-per-country-header-title-icon"
              onMouseEnter={(e) => showTooltip(e, "right")}
              onMouseLeave={hideTooltip}
            >
              <InfoIcon></InfoIcon>
            </div>
          </Tooltip>
        </h3>
        <div className="jobs-per-country-header-filters col-12 col-md-6  d-flex justify-content-end justify-content-lg-end align-items-center justify-content-sm-start">
          {countryDetailsDropDown && (
            <Dropdown
              dropdownList={countryDetailsDropDown}
              onValueChanged={handleCountryDropdownChange}
            />
          )}
          {jobDetailsDropDown && (
            <Dropdown
              dropdownList={jobDetailsDropDown}
              onValueChanged={handleJobDropdownChange}
            />
          )}
        </div>
      </div>
      <div className="jobs-per-country-chart">
        <MappedJobsPerCountryChart
          countryDetailsDropDown={countryDetailsDropDown}
          selectedCountry={countryDropDown}
          selectedJob={jobDropDown}
          isCountryLevel={isCountryLevel}
          selectedCountryObject={selectedCountryObject}
        />
      </div>
    </div>
  );
};

export default MappedJobsPerCountry;
