import React, { useEffect, useState } from "react";
import "./Dropdown.scss";
import SelectBox from "devextreme-react/select-box";
import ChevronDown from "../svg/ChevronDown";

const Dropdown = ({ dropdownList, onValueChanged }) => {
  const simpleProducts = [
    "HD Video Player",
    "SuperHD Video Player",
    "SuperPlasma 50",
    "SuperLED 50",
    "SuperLED 42",
    "SuperLCD 55",
    "SuperLCD 42",
    "SuperPlasma 65",
    "SuperLCD 70",
    "Projector Plus",
    "Projector PlusHT",
    "ExcelRemote IR",
    "ExcelRemote BT",
    "ExcelRemote IP",
  ];

  const [selectedValue, setSelectedValue] = useState(
    (dropdownList || simpleProducts)[0]
  );

  const handleValueChanged = (e) => {
    setSelectedValue(e.value);
    if (onValueChanged) onValueChanged(e);
  };

  const items = dropdownList ? dropdownList : simpleProducts;

  useEffect(() => {
    setSelectedValue(dropdownList[0]);
  }, [dropdownList]);

  const renderOption = (data) => {
    return (
      <div title={data} className="truncate">
        {data}
      </div>
    );
  };

  return (
    <div className="dropdown-container">
      <SelectBox
        items={items}
        itemRender={renderOption}
        placeholder="Choose Product"
        showClearButton={false}
        stylingMode={"filled"}
        className="dropdown"
        dropDownButtonRender={ChevronDown}
        width={"inherit"}
        value={selectedValue}
        onValueChanged={handleValueChanged}
      />
    </div>
  );
};

export default Dropdown;
