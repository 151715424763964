import React, { useState } from "react";
import "./MyFooter.scss";
import useTooltip from "../../hooks/useTooltip";
import { InfoIcon } from "../svg/InfoIcon";
import { WebsiteLogo } from "../svg/WebsiteLogo";
import EuCoLogo from "../../assets/images/eu-co.png";
import { pageNavigationData } from "../../data/pageNavigationData";
import ModalCancel from "../../assets/images/ModalCancel.svg";
import Tooltip from "../Tooltip/Tooltip";
import { isMobile } from "react-device-detect";

const MyFooter = () => {
  const { isVisible, position, showTooltip, hideTooltip } = useTooltip();
  const [modalVisible, setModalVisible] = useState(false);

  const dataSources = [
    "https://cv.ee",
    "https://cvonline.lt",
    "https://glassdoor.com",
    "https://himalayas.app",
    "https://indeed.com",
    "https://infosec-jobs.com",
    "https://Kandideeri.ee",
    "https://linkedin.com",
    "https://meetfrank.com",
    "https://mojedelo.com",
    "https://startup.jobs",
    "https://www.cvbankas.lt",
    "https://www.jobat.be",
    "https://www.profession.hu",
    "https://www.randstad.gr",
  ];
  const navList = pageNavigationData;

  return (
    <div className="myfooter-wrapper">
      <div className="myfooter-container row">
        <div className="myfooter-main-info col-12 col-md-6 d-flex justify-content-center align-items-center">
          <div className="myfooter-main-info">
            <div className="myfooter-main-info-title">
              <h3 className="myfooter-main-info-title-text m-0">
                About the Project
              </h3>
              <div className="myfooter-main-info-title-icon  ">
                <Tooltip
                  content={
                    <>
                      <p className="desc">
                        <p className="myfooter-main-info-learn-more mt-2">
                          How We Collected the Data
                        </p>
                        <p className="myfooter-main-info-learn-more-light ">
                          To ensure comprehensive coverage, we collected data by
                          scraping a variety of national and international job
                          boards across the seven national CyberHubs. Our
                          methodology involved gathering data from multiple
                          sources to capture a diverse range of job postings,
                          offering insights into the specific skills and roles
                          that are currently in demand.
                        </p>
                        <p className="myfooter-main-info-learn-more mt-2">
                          Data Sources
                        </p>
                        <ul
                          className={`${
                            !isMobile ? "two-column-list" : ""
                          } mb-3`}
                        >
                          {dataSources.map((data) => (
                            <li className="modal-ul-li">
                              <a href={data}>{data}</a>
                            </li>
                          ))}
                        </ul>
                        <p className="myfooter-main-info-learn-more mt-2">
                          Data Update Schedule
                        </p>
                        <p className="myfooter-main-info-learn-more-light ">
                          Our data is updated every six months to ensure it
                          reflects the latest trends and demands in the
                          cybersecurity job market. The most recent update was
                          completed in May 2024.
                        </p>
                      </p>
                    </>
                  }
                  isVisible={isVisible}
                  position={"right"}
                  size={"large"}
                >
                  <div
                    className="jobs-per-country-header-title-icon"
                    // onMouseEnter={(e) => showTooltip(e, "right")}
                    // onMouseLeave={hideTooltip}
                  >
                    <InfoIcon />
                  </div>
                </Tooltip>
              </div>
            </div>
            <p className="myfooter-main-info-learn-more-light mt-2">
              One of the The CyberHubs’ goals is to map the evolving landscape
              of cybersecurity skills and job opportunities across seven
              countries, including Belgium, Estonia, Greece, Hungary, Lithuania,
              Slovenia, and Spain. Our objective is to provide an accurate,
              up-to-date overview of the skills and roles in demand within the
              cybersecurity sector, helping both employers and job seekers
              navigate this critical industry.
              {/* <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setModalVisible(true)}
                >
                  Learn more &nbsp;
                </span>
                about how we collected the data. Our data is updated every six
                months, with the last update completed in May 2024" */}
            </p>
            {/* <p className="myfooter-main-info-learn-more mt-2">
              How We Collected the Data
            </p>
            <p className="myfooter-main-info-learn-more-light ">
              To ensure comprehensive coverage, we collected data by scraping a
              variety of national and international job boards across the seven
              national CyberHubs. Our methodology involved gathering data from
              multiple sources to capture a diverse range of job postings,
              offering insights into the specific skills and roles that are
              currently in demand.
            </p>
            <p className="myfooter-main-info-learn-more mt-2">Data Sources</p>
            <ul className="two-column-list mb-3">
              {dataSources.map((data) => (
                <li className="modal-ul-li">
                  <a href={data}>{data}</a>
                </li>
              ))}
            </ul>
            <p className="myfooter-main-info-learn-more mt-2">
              Data Update Schedule
            </p>
            <p className="myfooter-main-info-learn-more-light ">
              Our data is updated every six months to ensure it reflects the
              latest trends and demands in the cybersecurity job market. The
              most recent update was completed in May 2024.
            </p> */}
            <div className="myfooter-main-info-logos row">
              <div className="myfooter-main-info-logos-webiste-logo col-12 col-md-6 pt-2">
                <WebsiteLogo />
              </div>
              <div className="col-12 col-md-6 myfooter-main-info-logos-eu-union pt-2">
                <img src={EuCoLogo} alt="EuCoLogo" />
              </div>
            </div>
            <p className="myfooter-main-info-desc">
              Funded by the European Union. Views and opinions expressed are
              however those of the author(s) only and do not necessarily reflect
              those of the European Union or the European Education and Culture
              Executive Agency (EACEA). Neither the European Union nor EACEA can
              be held responsible for them.
            </p>
          </div>
        </div>
        <div className="col-md-2"></div>
        <div className="myfooter-main-links col-12 col-md-4  d-flex justify-content-space-between align-items-start">
          <div className="myfooter-main-links-nav  d-flex flex-column justify-content-center align-items-center">
            <h3 className="myfooter-main-links-nav-title m-0">CyberHubs</h3>
            <div className="myfooter-main-links-nav-list mt-2">
              {navList?.map((item) => {
                return (
                  <p
                    className={`myfooter-main-links-nav-list-item d-flex justify-content-center align-items-center ${
                      item.active ? "active" : ""
                    }`}
                    key={item.id}
                  >
                    <span>{item?.title}</span>
                  </p>
                );
              })}
            </div>
          </div>
          <div className="myfooter-main-links-socials d-flex flex-column justify-content-center align-items-center">
            <h3 className="myfooter-main-links-socials-title m-0">Social</h3>
            <div className="myfooter-main-links-socials-list d-flex flex-column justify-content-center align-items-center mt-2">
              <p className="myfooter-main-links-socials-list-item">
                <span>X (Twitter)</span>
              </p>
              <p className="myfooter-main-links-socials-list-item">
                <span> LinkedIn</span>
              </p>
              <p className="myfooter-main-links-socials-list-item">
                <span> Youtube</span>
              </p>
            </div>
          </div>
        </div>
        <div className="myfooter-copyright">
          <p className="myfooter-copyright-text">
            © 2024 by CyberHubs. All rights reserved.
          </p>
        </div>
      </div>

      {/* {modalVisible && (
        <div className="modal-overlay" onClick={() => setModalVisible(false)}>
          <div
            className="modal-content"
            style={{ width: "500px" }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-heading-div">
              <span className="modal-heading">Data Sources</span>
              <button onClick={() => setModalVisible(false)}>
                <img src={ModalCancel} alt="" />
              </button>
            </div>
            <hr className="modal-heading-hr" />
            &nbsp;
            <p className="desc" style={{ textAlign: "left" }}>
              <span className="modal-city-name">How We Collected the Data</span>
              <br />
              <br />
              <span className="modal-job-count">
                To ensure comprehensive coverage, we collected data by scraping
                a variety of national and international job boards across the
                seven national CyberHubs. Our methodology involved gathering
                data from multiple sources to capture a diverse range of job
                postings, offering insights into the specific skills and roles
                that are currently in demand.
              </span>
              <br />
              <br />
              <ul className="two-column-list">
                {dataSources.map((data) => (
                  <li className="modal-ul-li">
                    <a href={data}>{data}</a>
                  </li>
                ))}
              </ul>
              <br />
              <br />
              <span className="modal-city-name">Data Update Schedule</span>
              <br />
              <br />
              <span className="modal-job-count">
                Our data is updated every six months to ensure it reflects the
                latest trends and demands in the cybersecurity job market. The
                most recent update was completed in May 2024.
              </span>
            </p>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default MyFooter;
