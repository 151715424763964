// // import React, { useEffect, useState } from "react";
// // import "./SkillTrend.scss";
// // import PercentageChip from "./PercentageChip";


// // const Badges = ({ type, skill, increasing, percentage }) => {

// //     return (
// //         <div className='badgestyle'>
// //             <div>
// //                 <p>Top Trending {type}</p>
// //                 <p>{skill}</p>
// //                 <PercentageChip increasing={increasing} percentage={percentage} />
// //             </div>
// //         </div >
// //     );
// // };

// // export default Badges;
// import React, { useEffect, useState } from "react";
// import "./SkillTrend.scss";
// import PercentageChip from "./PercentageChip";

// const Badges = ({ type, skill, increasing, percentage }) => {
//     const colors = ["#2B00E5", "#25D09A", "#FD544F", "#FFF"]; // Define your 4 colours here
//     const [backgroundColor, setBackgroundColor] = useState("");

//     useEffect(() => {
//         // Select a random colour from the array
//         const randomColor = colors[Math.floor(Math.random() * colors.length)];
//         setBackgroundColor(randomColor);
//     }, []);

//     return (
//         <div className="badgestyle" style={{ backgroundColor }}>
//             <div>
//                 <p style={{ color: backgroundColor == "#FFF" ? "#0000" : "#fff" }}>Top Trending {type}</p>
//                 <p style={{ color: "#fff" }}>{skill}</p>
//                 <PercentageChip increasing={increasing} percentage={percentage} />
//             </div>
//         </div >
//     );
// };

// export default Badges;


// import React, { useEffect, useState } from "react";
// import "./SkillTrend.scss";
// import PercentageChip from "./PercentageChip";

// // Define your 4 colours in sequence
// const colors = ["#2B00E5", "#25D09A", "#FD544F", "#FFF"];
// let colorIndex = 0; // Global index to keep track of the sequence

// const Badges = ({ type, skill, increasing, percentage }) => {
//     const [backgroundColor, setBackgroundColor] = useState("");

//     useEffect(() => {
//         // Select the colour in sequence and increment the index
//         setBackgroundColor(colors[colorIndex]);
//         colorIndex = (colorIndex + 1) % colors.length; // Loop back to the start
//     }, []);

//     return (
//         <div className="badgestyle" style={{ backgroundColor, minWidth: "300px", maxWidth: "310px" }}>
//             <div style={{ width: "100%" }}>
//                 <span className="w-100" style={{ color: backgroundColor === "#FFF" ? "#000" : "#fff" }}>Top Trending {type}</span>
//                 <p className="w-100" style={{ color: backgroundColor === "#FFF" ? "#000" : "#fff", fontSize: "32px", overflow: "hidden" }}>{skill}</p>
//                 <PercentageChip backgroundColor={backgroundColor == "#FFF" ? true : false} increasing={increasing} percentage={percentage} />
//             </div>
//         </div>
//     );
// };

// export default Badges;


import React, { useEffect, useState } from "react";
import "./SkillTrend.scss";
import PercentageChip from "./PercentageChip";

// Define your 4 colours in sequence
const colors = ["#2B00E5", "#25D09A", "#FD544F", "#FFF"];
let colorIndex = 0; // Global index to keep track of the sequence

const Badges = ({ type, skill, increasing, percentage }) => {
    const [backgroundColor, setBackgroundColor] = useState("");

    useEffect(() => {
        // Select the colour in sequence and increment the index
        setBackgroundColor(colors[colorIndex]);
        colorIndex = (colorIndex + 1) % colors.length; // Loop back to the start
    }, []);

    return (
        <div className="badgestyle" style={{ backgroundColor, minWidth: "300px", maxWidth: "300px" }}>
            <div style={{ width: "100%" }}>
                <span className="w-100" style={{ color: backgroundColor === "#FFF" ? "#000" : "#fff" }}>
                    Top Trending {type}
                </span>
                <p
                    className="w-100 skill-text"
                    style={{ color: backgroundColor === "#FFF" ? "#000" : "#fff", fontSize: "32px", overflow: "hidden" }}
                    title={skill} // Tooltip with full skill text
                >
                    {skill}
                </p>
                <PercentageChip backgroundColor={backgroundColor == "#FFF" ? true : false} increasing={increasing} percentage={percentage} />
            </div>
        </div>
    );
};

export default Badges;

