import { registerGradient } from "devextreme/common/charts";

export const SeriesColors = {
  darkBlue: {
    base: "#18087E",
    fillId: registerGradient("linear", {
      rotationAngle: 90,
      colors: [
        {
          offset: "40%",
          color: "#2B00E5",
        },
        {
          offset: "100%",
          color: "#18087E",
        },
      ],
    }),
  },

  lightTeal: {
    base: "#077F45",
    fillId: registerGradient("linear", {
      rotationAngle: 90,
      colors: [
        {
          offset: "40%",
          color: "#52E0B4",
        },
        {
          offset: "100%",
          color: "#077F45",
        },
      ],
    }),
  },
  red: {
    base: "#830400",
    fillId: registerGradient("linear", {
      rotationAngle: 90,
      colors: [
        {
          offset: "40%",
          color: "#FD544F",
        },
        {
          offset: "100%",
          color: "#830400",
        },
      ],
    }),
  },
  orange: {
    base: "#654E20",
    fillId: registerGradient("linear", {
      rotationAngle: 90,
      colors: [
        {
          offset: "40%",
          color: "#FDC24F",
        },
        {
          offset: "100%",
          color: "#654E20",
        },
      ],
    }),
  },
  darkPurple: {
    base: "#4B215A",
    fillId: registerGradient("linear", {
      rotationAngle: 90,
      colors: [
        {
          offset: "40%",
          color: "#BB52E0",
        },
        {
          offset: "100%",
          color: "#4B215A",
        },
      ],
    }),
  },
  green: {
    base: "#1A5E21",
    fillId: registerGradient("linear", {
      rotationAngle: 90,
      colors: [
        {
          offset: "40%",
          color: "#41EB52",
        },
        {
          offset: "100%",
          color: "#1A5E21",
        },
      ],
    }),
  },
  darkOrange: {
    base: "#662500",
    fillId: registerGradient("linear", {
      rotationAngle: 90,
      colors: [
        {
          offset: "40%",
          color: "#FF5C00",
        },
        {
          offset: "100%",
          color: "#662500",
        },
      ],
    }),
  },
  darkYellow: {
    base: "#665600",
    fillId: registerGradient("linear", {
      rotationAngle: 90,
      colors: [
        {
          offset: "40%",
          color: "#FFD600",
        },
        {
          offset: "100%",
          color: "#665600",
        },
      ],
    }),
  },
  yellow: {
    base: "#586600",
    fillId: registerGradient("linear", {
      rotationAngle: 90,
      colors: [
        {
          offset: "40%",
          color: "#DBFF00",
        },
        {
          offset: "100%",
          color: "#586600",
        },
      ],
    }),
  },
  lightBlue: {
    base: "#006066",
    fillId: registerGradient("linear", {
      rotationAngle: 90,
      colors: [
        {
          offset: "40%",
          color: "#00F0FF",
        },
        {
          offset: "100%",
          color: "#006066",
        },
      ],
    }),
  },
  middleBlue: {
    base: "#002F66",
    fillId: registerGradient("linear", {
      rotationAngle: 90,
      colors: [
        {
          offset: "40%",
          color: "#0075FF",
        },
        {
          offset: "100%",
          color: "#002F66",
        },
      ],
    }),
  },
  purple: {
    base: "#640066",
    fillId: registerGradient("linear", {
      rotationAngle: 90,
      colors: [
        {
          offset: "40%",
          color: "#FA00FF",
        },
        {
          offset: "100%",
          color: "#640066",
        },
      ],
    }),
  },
  darkBlueOp: {
    base: "#2B00E511",
    fillId: "#2B00E511",
  },
  lightTealOp: {
    base: "#52E0B411",
    fillId: "#52E0B411",
  },
  redOp: {
    base: "#FD544F11",
    fillId: "#FD544F11",
  },
  orangeOp: {
    base: "#FDC24F11",
    fillId: "#FDC24F11",
  },
  darkPurpleOp: {
    base: "#BB52E011",
    fillId: "#BB52E011",
  },
  greenOp: {
    base: "#41EB5211",
    fillId: "#41EB5211",
  },
  darkOrangeOp: {
    base: "#FF5C0011",
    fillId: "#FF5C0011",
  },
  darkYellowOp: {
    base: "#FFD60011",
    fillId: "#FFD60011",
  },
  yellowOp: {
    base: "#DBFF0011",
    fillId: "#DBFF0011",
  },
  lightBlueOp: {
    base: "#00F0FF11",
    fillId: "#00F0FF11",
  },
  middleBlueOp: {
    base: "#0075FF11",
    fillId: "#0075FF11",
  },
  purpleOp: {
    base: "#FA00FF11",
    fillId: "#FA00FF11",
  },
};
