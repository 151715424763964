// import React, { useEffect, useState } from "react";

// import Plot from "react-plotly.js";
// import "./SkillAnalysisPieChart.scss";
// import { isMobile, isTablet } from "react-device-detect";
// const SkillAnalysisPieChart = ({
//   selectedCountry,
//   selectedJob,
//   isCountryLevel,
//   countryDetailsDropDown,
// }) => {
//   const [sunDuskData, setSunDuskData] = useState(null);
//   const fetchJobsPerCountry = async () => {
//     try {
//       const response = await fetch(
//         `https://cyberhubs.rhibhus.co.uk/api/sunburst_data/?level=${
//           isCountryLevel ? "country" : "city"
//         }&location=${
//           selectedCountry !== null
//             ? selectedCountry === "All Cities/Regions" ||
//               selectedCountry === "All Countries"
//               ? "all"
//               : selectedCountry
//             : "all"
//         }&job_title=${
//           selectedJob === "All ECSF Roles" || selectedJob === null
//             ? "all"
//             : selectedJob?.replace("&", "%26")
//         }`,
//         {
//           method: "GET",
//         }
//       );
//       const data = await response.json();
//       setSunDuskData(data);
//     } catch (error) {
//     }
//   };

//   useEffect(() => {
//     fetchJobsPerCountry();
//     handleShadows();
//   }, [selectedCountry, selectedJob, countryDetailsDropDown]);

//   const formatLabel = (label) => {
//     const maxLineLength = 15; // Customize the max length of each line
//     if (label.length <= maxLineLength) return label;

//     // Split the label into multiple lines based on the max length
//     const words = label.split(" ");
//     let line = "";
//     const result = [];

//     words.forEach((word) => {
//       if (line.length + word.length <= maxLineLength) {
//         line += `${word} `;
//       } else {
//         result.push(line.trim());
//         line = `${word} `;
//       }
//     });
//     if (line) result.push(line.trim());

//     return result.join("<br>");
//   };

//   const formattedLabels =
//     sunDuskData && sunDuskData["labels"]?.map((label) => formatLabel(label));

//   const data = [
//     {
//       type: "sunburst",
//       maxdepth: 2,
//       ids: sunDuskData && sunDuskData.ids,
//       labels: sunDuskData && formattedLabels,
//       parents: sunDuskData && sunDuskData.parents,
//       values: sunDuskData && sunDuskData.values,
//       branchvalues: "total",
//       leaf: { opacity: 0.4 },
//       outsidetextfont: { size: 16, color: "#000" },
//       // marker: {
//       //   line: { width: 0.1, color: "#000" },
//       //   colors: ["#FFFFFF"],
//       // },
//       marker: {
//         colors: sunDuskData?.parents.map((parent, index) =>
//           parent === "root" ? getColorForRootLabel(index) : "#FFFFFF"
//         ),
//       },
//       hoverlabel: {
//         bordercolor: "transparent",
//         font: { color: "#000", shadow: "auto", size: 16 },
//         insidetextfont: { color: "#fff", shadow: "auto", size: 16 },
//       },
//       hoverinfo: "label+value+percent entry+percent parent",
//       insidetextorientation: "radial",
//       showSendToCloud: true,
//       textposition: "none",
//     },
//   ];
//   const colors = ["#7657FF", "#FD544F", "#25D09A", "#0B00E6", "#F7F7F7"];
//   const getColorForRootLabel = (index) => colors[index % colors.length];

//   const layout = {
//     margin: {
//       l: isMobile || isTablet ? 20 : 30,
//       r: isMobile || isTablet ? 20 : 25,
//       b: isMobile || isTablet ? 25 : 30,
//       t: isMobile || isTablet ? 5 : 20,
//     },
//     // sunburstcolorway: colors,
//     // extendsunburstcolorway: true,
//     showlegend: false,
//   };

//   const addSVGFilterToSunburst = () => {
//     const plotElement = document.querySelector("#skillVsJob");
//     if (plotElement) {
//       const svg = plotElement.querySelector(".main-svg");
//       if (svg) {
//         // Create a defs element if it doesn't exist
//         let defs = svg.querySelector("defs");
//         if (!defs) {
//           defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");
//           svg.appendChild(defs);
//         }

//         // Add the filter definition
//         const filterId = "sunburstShadow";
//         defs.innerHTML = `
//           <filter id="${filterId}" x="-50%" y="-50%" width="200%" height="200%">
//             <feGaussianBlur in="SourceAlpha" stdDeviation="7" result="blur"/>
//             <feOffset dx="0" dy="0" result="offsetBlur"/>
//             <feFlood flood-color="rgba(0, 0, 0, 0.6)" flood-opacity="1" result="shadowColor"/>
//             <feComposite in="shadowColor" in2="offsetBlur" operator="in" result="shadowBlur"/>
//             <feMerge>
//               <feMergeNode in="shadowBlur"/>
//               <feMergeNode in="SourceGraphic"/>
//             </feMerge>
//           </filter>
//         `;

//         // Apply the filter to all sunburst slices
//         const sunburstSlices = svg.querySelectorAll(".sunburst");
//         // const sunburstSlices = svg.querySelectorAll(".sunburst path");
//         sunburstSlices.forEach((slice) => {
//           slice.setAttribute("filter", `url(#${filterId})`);
//         });
//       }
//     }
//   };

//   const handleShadows = () => {
//     const timer = setTimeout(addSVGFilterToSunburst, 1000);
//     return () => clearTimeout(timer);
//   };

//   const rootLabelColors = sunDuskData?.labels.reduce((acc, label, index) => {
//     if (sunDuskData.parents[index] === "root") {
//       acc[label] = colors && getColorForRootLabel(Object.keys(acc).length);
//     }
//     return acc;
//   }, {});

//   return (
//     <div>
//       {sunDuskData && (
//         <div className="row">
//           <div className="col-12" style={{ marginLeft: "20px" }}>
//             {Object.entries(rootLabelColors).map(([label, color], index) => (
//               <div
//                 key={index}
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   marginRight: "15px",
//                   marginBottom: "5px",
//                 }}
//               >
//                 <div
//                   style={{
//                     width: "15px",
//                     height: "15px",
//                     backgroundColor: color,
//                     marginRight: "5px",
//                     borderRadius: "50%",
//                   }}
//                 />
//                 <span style={{ fontSize: "14px" }}>{label}</span>
//               </div>
//             ))}
//           </div>
//           <Plot
//             divId="skillVsJob"
//             className="col-12"
//             onInitialized={handleShadows}
//             onAfterPlot={handleShadows}
//             onAnimated={handleShadows}
//             data={data}
//             layout={layout}
//             useResizeHandler={true}
//             style={{
//               width: isMobile || isTablet ? 300 : 400,
//               height: isMobile || isTablet ? 300 : 400,
//             }}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default SkillAnalysisPieChart;

import React, { useEffect, useState, useMemo } from "react";
import Plot from "react-plotly.js";
import "./SkillAnalysisPieChart.scss";
import { isMobile, isTablet } from "react-device-detect";

const SkillAnalysisPieChart = ({
  selectedCountry,
  selectedJob,
  isCountryLevel,
  countryDetailsDropDown,
}) => {
  const [sunDuskData, setSunDuskData] = useState(null);

  const colors = ["#7657FF", "#FD544F", "#25D09A", "#0B00E6", "#F7F7F7"];

  const fetchJobsPerCountry = async () => {
    try {
      const response = await fetch(
        `https://cyberhubs.rhibhus.co.uk/api/sunburst_data/?level=${
          isCountryLevel ? "country" : "city"
        }&location=${
          selectedCountry !== null
            ? selectedCountry === "All Cities/Regions" ||
              selectedCountry === "All Countries"
              ? "all"
              : selectedCountry
            : "all"
        }&job_title=${
          selectedJob === "All ECSF Roles" || selectedJob === null
            ? "all"
            : selectedJob?.replace("&", "%26")
        }`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      setSunDuskData(data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchJobsPerCountry();
  }, [selectedCountry, selectedJob, countryDetailsDropDown]);

  const formatLabel = (label) => {
    const maxLineLength = 15;
    if (label.length <= maxLineLength) return label;

    const words = label.split(" ");
    let line = "";
    const result = [];

    words.forEach((word) => {
      if (line.length + word.length <= maxLineLength) {
        line += `${word} `;
      } else {
        result.push(line.trim());
        line = `${word} `;
      }
    });
    if (line) result.push(line.trim());

    return result.join("<br>");
  };

  const { plotData, rootLabelColors } = useMemo(() => {
    if (!sunDuskData) return { plotData: [], rootLabelColors: {} };

    const rootLabels = sunDuskData.labels.filter(
      (_, index) => sunDuskData.parents[index] === "root"
    );
    const rootLabelColors = {};
    rootLabels.forEach((label, index) => {
      rootLabelColors[label] = colors[index % colors.length];
    });

    const plotData = [
      {
        type: "sunburst",
        maxdepth: 2,
        ids: sunDuskData?.ids,
        labels: sunDuskData?.labels.map(formatLabel),
        parents: sunDuskData?.parents,
        values: sunDuskData?.values,
        branchvalues: "total",
        leaf: { opacity: 0.4 },
        outsidetextfont: { size: 16, color: "#000" },
        textinfo: "label",
        marker: {
          line: { width: 0.3, color: "#000" },
          colors: sunDuskData.labels.map((label, index) =>
            sunDuskData.parents[index] !== ""
              ? sunDuskData.parents[index] === "root"
                ? rootLabelColors[label]
                : "none"
              : "#fff"
          ),
        },
        hoverlabel: {
          bordercolor: "transparent",
          font: { color: "#fff", shadow: "auto", size: 16 },
        },
        hoverinfo: "label+value+percent entry+percent parent",
        insidetextorientation: "radial",
        texttemplate: sunDuskData.labels.map((label, index) =>
          sunDuskData.parents[index] === "root"
            ? `<span style="color:white; font-size:14px">${label}</span>`
            : `%{label}`
        ),
        hovertemplate: sunDuskData.labels.map((label, index) =>
          sunDuskData.parents[index] === ""
            ? `<span style="color:black;"><b>${label}</b><br>Value: %{value:,.0f}<br>Percentage: %{percentParent:.0%}</span><extra></extra>`
            : `<span style="color:white;"><b>${label}</b><br>Value: %{value:,.0f}<br>Percentage: %{percentParent:.0%}</span><extra></extra>`
        ),
      },
    ];

    return { plotData, rootLabelColors };
  }, [sunDuskData]);

  const layout = {
    margin: {
      l: isMobile || isTablet ? 20 : 30,
      r: isMobile || isTablet ? 20 : 25,
      b: isMobile || isTablet ? 25 : 30,
      t: isMobile || isTablet ? 5 : 20,
    },
  };

  const addSVGFilterToSunburst = () => {
    const plotElement = document.querySelector("#skillVsJob");
    if (plotElement) {
      const svg = plotElement.querySelector(".main-svg");
      if (svg) {
        let defs = svg.querySelector("defs");
        if (!defs) {
          defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");
          svg.appendChild(defs);
        }

        const filterId = "sunburstShadow";
        defs.innerHTML = `
          <filter id="${filterId}" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="7" result="blur"/>
            <feOffset dx="0" dy="0" result="offsetBlur"/>
            <feFlood flood-color="rgba(0, 0, 0, 0.6)" flood-opacity="1" result="shadowColor"/>
            <feComposite in="shadowColor" in2="offsetBlur" operator="in" result="shadowBlur"/>
            <feMerge>
              <feMergeNode in="shadowBlur"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        `;

        const sunburstSlices = svg.querySelectorAll(".sunburst");
        sunburstSlices.forEach((slice) => {
          slice.setAttribute("filter", `url(#${filterId})`);
        });
      }
    }
  };

  return (
    <div>
      {sunDuskData && (
        <div className="row">
          <div className="col-12">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginBottom: "10px",
              }}
            >
              {Object.entries(rootLabelColors).map(([label, color], index) => (
                <div
                  key={index}
                  className="d-flex align-items-center me-3 mb-1"
                >
                  <div
                    className="me-1"
                    style={{
                      width: "15px",
                      height: "15px",
                      backgroundColor: color,
                      borderRadius: "50%",
                    }}
                  />
                  <span style={{ fontSize: "14px" }}>{label}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center align-items-center">
            <Plot
              divId="skillVsJob"
              onInitialized={addSVGFilterToSunburst}
              onAfterPlot={addSVGFilterToSunburst}
              onAnimated={addSVGFilterToSunburst}
              data={plotData}
              layout={layout}
              useResizeHandler={true}
              style={{
                width: isMobile || isTablet ? 300 : 400,
                height: isMobile || isTablet ? 300 : 400,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SkillAnalysisPieChart;
