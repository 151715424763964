import React from "react";
import "./TopRoleTitlesCard.scss";
import PropTypes from "prop-types";
const TopRoleTitlesCard = ({ cardData }) => {
  const { role, skills } = cardData;
  const capitalizeFirstLetterOfEachWord = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="topRoleTitles-card">
      <h2 className="topRoleTitles-card-title">{role}</h2>
      <div className="topRoleTitles-card-skills">
        {skills.map((skill) => (
          <div key={skill.id} className="topRoleTitles-card-skills-item">
            <span className="topRoleTitles-card-skills-item-name">
              {capitalizeFirstLetterOfEachWord(skill?.skill?.replace("_", " "))}
            </span>
            <span className="topRoleTitles-card-skills-item-count">
              {skill.conunt}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

TopRoleTitlesCard.propTypes = {
  cardData: PropTypes.shape({
    role: PropTypes.string.isRequired,
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        skill: PropTypes.string.isRequired,
        conunt: PropTypes.number.isRequired, // Note: correct spelling for count
      })
    ).isRequired,
  }).isRequired,
};

export default TopRoleTitlesCard;
