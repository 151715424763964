import React, { useEffect, useState } from "react";
import "./SkillsAnalysisSection.scss";
import SkillsAnalysisLineChart from "./SkillsAnalysisLineChart/SkillsAnalysisLineChart";
import Dropdown from "../../Dropdown/Dropdown";
import Tooltip from "../../Tooltip/Tooltip";
import useTooltip from "../../../hooks/useTooltip";
import { InfoIcon } from "../../svg/InfoIcon";
const SkillsAnalysisSection = ({
  countryDetailsDropDown,
  isCountryLevel,
  jobDetailsOptions,
  selectedCountryObject,
}) => {
  const [countryDropDown, setCountryDropDown] = useState(null);
  const [jobDropDown, setJobDropDown] = useState(null);
  const [jobDropDownDetails, setJobDropDownDetails] =
    useState(jobDetailsOptions);
  const { isVisible, position, showTooltip, hideTooltip } = useTooltip();
  const handleCountryDropdownChange = (e) => {
    setCountryDropDown(e.value);
  };

  const handleJobDropdownChange = (e) => {
    setJobDropDown(e.value);
  };

  const fetchPerticularJobs = async (e) => {
    const response = await fetch(
      `https://cyberhubs.rhibhus.co.uk/api/job_title_dropdown/?level=${
        isCountryLevel || e === "All Cities/Regions" ? "country" : "city"
      }&locations=${
        e !== null
          ? e === "All Countries"
            ? "all"
            : e === "All Cities/Regions"
            ? selectedCountryObject.country
            : e
          : "all"
      }`,
      {
        method: "GET",
      }
    );
    const respon = await response.json();
    setJobDropDownDetails(["All ECSF Roles", ...respon]);
  };
  /* eslint-disable */
  useEffect(() => {
    fetchPerticularJobs(countryDropDown);
  }, [countryDropDown]);
  return (
    <div className="skillsAnalysisSection">
      <h3 className="skillsAnalysisSection-title">Skills Needs Analysis</h3>
      <div className="skillsAnalysisSection-content">
        <div className="skillsAnalysisSection-content-header row">
          <div className="skillsAnalysisSection-content-header-container col-12 col-md-6  mb-3">
            <h3 className="skillsAnalysisSection-content-header-title m-0 ">
              ECSF Mapped Top Skills in Demand
            </h3>
            <Tooltip
              content={
                <>
                  {/* <p className="title">What does ECSF mean?</p> */}
                  <p className="desc">
                    This chart presents both relative and raw counts of skills
                    based on ECSF job titles. The relative counts are calculated
                    to provide a normalised view of skill importance across
                    different jobs.
                    <br />
                    <br />
                    <u>Here's how the calculation works:</u>
                    <br />
                    <u> Raw Counts: </u>These are the actual number of times a
                    skill appears across all or a specific ECSF job title.{" "}
                    <br />
                    <u>Relative Counts:</u> These are scaled representations of
                    the raw counts, allowing for easier comparison between
                    skills, especially when dealing with varying total job
                    numbers.
                    <br /> The calculation involves these steps: <br />
                    <u>a. Find the maximum count:</u> Identify the skill with
                    the highest raw count.
                    <br />
                    <u>b. Calculate the maximum percentage:</u> Divide the
                    maximum count by the total number of jobs and multiply by
                    10. This gives a baseline for scaling.
                    <br />
                    <u> c. Determine the scaling factor:</u> Divide 10 by the
                    maximum percentage. This ensures that the most common skill
                    will have a relative count of 10, with other skills scaled
                    proportionally.
                  </p>
                </>
              }
              isVisible={isVisible}
              position={"right"}
              size={"large"}
            >
              <div
                className="jobs-per-country-header-title-icon"
                onMouseEnter={(e) => showTooltip(e, "right")}
                onMouseLeave={hideTooltip}
              >
                <InfoIcon></InfoIcon>
              </div>
            </Tooltip>
          </div>
          <div className="skillsAnalysisSection-content-header-filters col-12 col-md-6 d-flex  justify-content-lg-end align-items-center justify-content-sm-start">
            <Dropdown
              dropdownList={countryDetailsDropDown}
              onValueChanged={handleCountryDropdownChange}
            />
            <Dropdown
              dropdownList={jobDropDownDetails}
              onValueChanged={handleJobDropdownChange}
            />
          </div>
        </div>
        <div className="skillsAnalysisSection-content-chart">
          <SkillsAnalysisLineChart
            selectedCountry={countryDropDown}
            selectedJob={jobDropDown}
            isCountryLevel={isCountryLevel}
            selectedCountryObject={selectedCountryObject}
            countryDetailsDropDown={countryDetailsDropDown}
          />
        </div>
      </div>
    </div>
  );
};

export default SkillsAnalysisSection;
