import React from "react";
import "./Welcome.scss";
const Welcome = () => {
  return (
    <div className="welcome">
      <h3 className="welcome-title">Welcome</h3>
      <p className="welcome-desc">
        Welcome to the CyberHubs Skills and Jobs Dashboard. Are you interested
        in cybersecurity skills and job opportunities? This dynamic dashboard
        will provide you with information on the latest trends. Our data is
        updated every six months and was last updated on the <b>31 May 2024</b>.
        <br></br>
        <br></br>
        Explore the various features to gain valuable insights into the
        cybersecurity landscape, identify top skills and roles in demand, and
        discover emerging job opportunities across different EU countries.
      </p>
    </div>
  );
};

export default Welcome;
