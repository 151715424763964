import React, { useEffect, useState } from "react";
import "./JobAnalysis.scss";
import TopRoleTitles from "./TopRoleTitles/TopRoleTitles";
import SkillAnalysis from "./SkillAnalysis/SkillAnalysis";
import Dropdown from "../../Dropdown/Dropdown";
const JobAnalysis = ({
  countryDetailsDropDown,
  isCountryLevel,
  jobDetailsOptions,
  selectedCountryObject,
}) => {
  const [countryDropDown, setCountryDropDown] = useState(null);
  const [jobDetailsDropDown, setJobDetailsDropDown] =
    useState(jobDetailsOptions);

  const fetchPerticularJobs = async (e) => {
    const response = await fetch(
      `https://cyberhubs.rhibhus.co.uk/api/job_title_dropdown/?level=${
        isCountryLevel || e === "All Cities/Regions" ? "country" : "city"
      }&locations=${
        e !== null
          ? e === "All Countries"
            ? "all"
            : e === "All Cities/Regions"
            ? selectedCountryObject.country
            : e
          : "all"
      }`,
      {
        method: "GET",
      }
    );
    const respon = await response.json();
    setJobDetailsDropDown(["All ECSF Roles", ...respon]);
  };

  const handleCountryDropdownChange = (e) => {
    setCountryDropDown(e.value);
  };

  useEffect(() => {
    fetchPerticularJobs(countryDropDown);
  }, [countryDropDown]);

  return (
    <>
      <div className="jobAnalysis-header row ">
        <h3 className="jobAnalysis-header-title col-12 col-md-6">
          Job Market Analysis
        </h3>
        <div className="jobAnalysis-header-filters col-12 col-md-6 d-flex justify-content-lg-end align-items-center justify-content-sm-start">
          <Dropdown
            dropdownList={countryDetailsDropDown}
            onValueChanged={handleCountryDropdownChange}
          />
        </div>
      </div>
      <div className="jobAnalysis mb-3 d-flex flex-column justify-content-center align-items-center">
        <div className="jobAnalysis-content row">
          <div className="col-12 col-lg-6 ps-0 pe-lg-2 pe-0 contentdiv">
            <TopRoleTitles
              selectedCountry={countryDropDown}
              isCountryLevel={isCountryLevel}
              selectedCountryObject={selectedCountryObject}
              countryDetailsDropDown={countryDetailsDropDown}
            />
          </div>
          <div className="col-12 col-lg-6 pe-0 ps-lg-2 ps-0">
            <SkillAnalysis
              selectedCountry={countryDropDown}
              jobDetailsOptions={jobDetailsDropDown}
              isCountryLevel={isCountryLevel}
              selectedCountryObject={selectedCountryObject}
              countryDetailsDropDown={countryDetailsDropDown}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default JobAnalysis;
