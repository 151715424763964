import React, { useEffect, useState } from "react";
import "./TopRoleTitles.scss";
import { InfoIcon } from "../../../svg/InfoIcon";
import TopRoleTitlesCard from "./TopRoleTitlesCard/TopRoleTitlesCard";
import useTooltip from "../../../../hooks/useTooltip";
import Tooltip from "../../../Tooltip/Tooltip";
// import { topRoleTitlesData } from "../../../../data/topRoleTitlesData";
const TopRoleTitles = ({
  selectedCountry,
  isCountryLevel,
  selectedCountryObject,
  countryDetailsDropDown,
}) => {
  const [topRoleTitles, setTopRoleTitles] = useState();

  const { isVisible, position, showTooltip, hideTooltip } = useTooltip();
  const fetchRoleTitles = async (selectedCountry) => {
    try {
      await fetch(
        `https://cyberhubs.rhibhus.co.uk/api/top_jobs_skills/?level=${
          isCountryLevel || selectedCountry === "All Cities/Regions"
            ? "country"
            : "city"
        }&location=${
          selectedCountry !== null
            ? selectedCountry === "All Countries"
              ? "all"
              : selectedCountry === "All Cities/Regions"
              ? selectedCountryObject.country
              : selectedCountry
            : "all"
        }`,
        {
          method: "GET",
          // headers: {
          //     "content-type": "application/json;",
          //     Authorization: "",
          // },
        }
      )
        .then((response) => response.json())
        .then((respon) => {
          const fetchedRoles = respon.data.map((role, index) => {
            // Extract skills dynamically
            const skills = Object.entries(role)
              .filter(([key]) => key !== "job_title") // Exclude job_title
              .map(([key, value], skillIndex) => ({
                id: skillIndex + 1,
                skill: key,
                conunt: value, // or use 'count' if that's correct
              }));

            return {
              id: index + 1,
              role: role.job_title,
              skills,
            };
          });
          setTopRoleTitles(fetchedRoles);
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  /* eslint-disable */
  useEffect(() => {
    fetchRoleTitles(selectedCountry);
  }, [selectedCountry, countryDetailsDropDown]);

  return (
    <div className="topRoleTitles">
      <div className="topRoleTitles-header">
        <h3 className="topRoleTitles-header-title m-0">Top 5 ECSF Roles</h3>
        <Tooltip
          content={
            <>
              <p className="title">What does ECSF mean?</p>
              <p className="desc">
                The European Cybersecurity Skills Framework (ECSF) provides a
                practical tool to support the identification and articulation of
                tasks, competences, skills and knowledge associated with the
                roles of European cybersecurity professionals. The main purpose
                of the framework is to create a common understanding between
                individuals, employers and learning providers across EU Member
                States, making it a valuable tool to bridge the gap between the
                cybersecurity professional workplace and learning environments.
                (ENISA, 2022) Learn more about the ECSF here: &nbsp;
              </p>
            </>
          }
          footer={
            <>
              <a
                href="https://cyberhubs.eu/resource/european-cybersecurity-skills-framework-the-common-language-for-cybersecurity-professional-workforce-development/"
                target="_blank"
              >
                Learn more about the ECSF
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 14"
                  fill="none"
                  style={{ marginLeft: 5 }}
                >
                  <path
                    d="M12 9.16667V13.1667C12 13.5203 11.8595 13.8594 11.6095 14.1095C11.3594 14.3595 11.0203 14.5 10.6667 14.5H3.33333C2.97971 14.5 2.64057 14.3595 2.39052 14.1095C2.14048 13.8594 2 13.5203 2 13.1667V5.83333C2 5.47971 2.14048 5.14057 2.39052 4.89052C2.64057 4.64048 2.97971 4.5 3.33333 4.5H7.33333"
                    stroke="black"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M10 2.5H14V6.5" fill="#2B00E5" />
                  <path
                    d="M10 2.5H14V6.5"
                    stroke="black"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.66602 9.83333L13.9993 2.5"
                    stroke="black"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </>
          }
          isVisible={isVisible}
          position={"right"}
          size={"large"}
        >
          <div
            className="jobs-per-country-header-title-icon"
            onMouseEnter={(e) => showTooltip(e, "right")}
            onMouseLeave={hideTooltip}
          >
            <InfoIcon></InfoIcon>
          </div>
        </Tooltip>
      </div>
      {topRoleTitles && (
        <div className="topRoleTitles-content">
          {topRoleTitles.map((item) => {
            return <TopRoleTitlesCard key={item.id} cardData={item} />;
          })}
        </div>
      )}
    </div>
  );
};

export default TopRoleTitles;
