import { useState, useCallback } from "react";

const useTooltip = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState("top");

  const showTooltip = useCallback((event, tooltipPosition = "top") => {
    setIsVisible(true);
    setPosition(tooltipPosition);
  }, []);

  const hideTooltip = useCallback(() => {
    setIsVisible(false);
  }, []);

  return {
    isVisible,
    position,
    showTooltip,
    hideTooltip,
  };
};

export default useTooltip;
