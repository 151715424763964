import React from "react";
import PropTypes from "prop-types";
import "./Button.scss";

const Button = ({ onClick, variant, size, children }) => {
  return (
    <button onClick={onClick} className={`button ${variant} ${size}`}>
      {children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(["primary", "secondary"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  children: PropTypes.node.isRequired,
};

export default Button;
