import React, { useState } from "react";
import "./SkillAnalysis.scss";
import { InfoIcon } from "../../../svg/InfoIcon";
import SkillAnalysisPieChart from "./SkillAnalysisPieChart/SkillAnalysisPieChart";
import Dropdown from "../../../Dropdown/Dropdown";
import useTooltip from "../../../../hooks/useTooltip";
import Tooltip from "../../../Tooltip/Tooltip";

const SkillAnalysis = ({
  selectedCountry,
  jobDetailsOptions,
  isCountryLevel,
  countryDetailsDropDown,
}) => {
  const [jobDropDown, setJobDropDown] = useState(null);
  const { isVisible, position, showTooltip, hideTooltip } = useTooltip();

  const handleJobDropdownChange = (e) => {
    setJobDropDown(e.value);
  };
  return (
    <div className="skillAnalysis">
      <div className="skillAnalysis-header row ">
        <h3 className="skillAnalysis-header-title  col-12 col-md-7 d-flex  justify-content-start align-items-lg-start align-items-center">
          ECSF Mapped In-demand Skills
          <Tooltip
            content={
              <>
                {/* <p className="title">What does ECSF mean?</p> */}
                <p className="desc">
                  This interactive sunburst chart provides a hierarchical and
                  dynamic view of skills associated with a specific or all ECSF
                  job title. The chart is initially organised in two concentric
                  circles, with the job title at the center and skill categories
                  in the outer ring. The size of each slice in the outer ring is
                  proportional to the number of skills it represents. Clicking
                  on a skill category transforms the chart to present the top 5
                  skills for each category based on count.
                </p>
              </>
            }
            // footer={
            //   <>
            //     <a
            //       href="https://cyberhubs.eu/resource/european-cybersecurity-skills-framework-the-common-language-for-cybersecurity-professional-workforce-development/"
            //       target="_blank"
            //     >
            //       Learn more about the ECSF
            //       <svg
            //         xmlns="http://www.w3.org/2000/svg"
            //         width="16"
            //         height="16"
            //         viewBox="0 0 16 14"
            //         fill="none"
            //         style={{ marginLeft: 5 }}
            //       >
            //         <path
            //           d="M12 9.16667V13.1667C12 13.5203 11.8595 13.8594 11.6095 14.1095C11.3594 14.3595 11.0203 14.5 10.6667 14.5H3.33333C2.97971 14.5 2.64057 14.3595 2.39052 14.1095C2.14048 13.8594 2 13.5203 2 13.1667V5.83333C2 5.47971 2.14048 5.14057 2.39052 4.89052C2.64057 4.64048 2.97971 4.5 3.33333 4.5H7.33333"
            //           stroke="black"
            //           stroke-width="1.33333"
            //           stroke-linecap="round"
            //           stroke-linejoin="round"
            //         />
            //         <path d="M10 2.5H14V6.5" fill="#2B00E5" />
            //         <path
            //           d="M10 2.5H14V6.5"
            //           stroke="black"
            //           stroke-width="1.33333"
            //           stroke-linecap="round"
            //           stroke-linejoin="round"
            //         />
            //         <path
            //           d="M6.66602 9.83333L13.9993 2.5"
            //           stroke="black"
            //           stroke-width="1.33333"
            //           stroke-linecap="round"
            //           stroke-linejoin="round"
            //         />
            //       </svg>
            //     </a>
            //   </>
            // }
            isVisible={isVisible}
            position={"left"}
            size={"large"}
          >
            <div
              className="jobs-per-country-header-title-icon"
              onMouseEnter={(e) => showTooltip(e, "left")}
              onMouseLeave={hideTooltip}
            >
              <InfoIcon></InfoIcon>
            </div>
          </Tooltip>
        </h3>
        <div className="skillAnalysis-header-filters col-12 col-md-5 d-flex  justify-content-lg-end align-items-center justify-content-sm-start">
          <Dropdown
            dropdownList={jobDetailsOptions}
            onValueChanged={handleJobDropdownChange}
          />
        </div>
      </div>
      <div className="skillAnalysis-content">
        <SkillAnalysisPieChart
          selectedCountry={selectedCountry}
          selectedJob={jobDropDown}
          isCountryLevel={isCountryLevel}
          countryDetailsDropDown={countryDetailsDropDown}
        />
      </div>
    </div>
  );
};

export default SkillAnalysis;
