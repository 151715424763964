import React from "react";

const WorningIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
    >
      <path
        d="M17.6493 6.58797L3.53264 30.1546C3.24158 30.6587 3.08758 31.2301 3.08595 31.8122C3.08432 32.3942 3.23512 32.9665 3.52335 33.4722C3.81157 33.9778 4.22718 34.3992 4.72882 34.6944C5.23045 34.9896 5.80063 35.1482 6.38264 35.1546H34.616C35.198 35.1482 35.7682 34.9896 36.2698 34.6944C36.7714 34.3992 37.187 33.9778 37.4753 33.4722C37.7635 32.9665 37.9143 32.3942 37.9127 31.8122C37.911 31.2301 37.757 30.6587 37.466 30.1546L23.3493 6.58797C23.0522 6.09815 22.6338 5.69317 22.1346 5.41211C21.6354 5.13105 21.0722 4.9834 20.4993 4.9834C19.9264 4.9834 19.3632 5.13105 18.864 5.41211C18.3648 5.69317 17.9464 6.09815 17.6493 6.58797Z"
        stroke="#FF8800"
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 15.1543V21.821"
        stroke="#FF8800"
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 28.4873H20.5167"
        stroke="#FF8800"
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WorningIcon;
