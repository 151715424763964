export const pageNavigationData = [
  {
    id: 1,
    title: "About us",
    link: "https://cyberhubs.eu/about-us/",
    active: false,
  },
  {
    id: 2,
    title: "News",
    link: "https://cyberhubs.eu/news/",
    active: false,
  },
  {
    id: 3,
    title: "Events",
    link: "https://cyberhubs.eu/events/",
    active: false,
  },
  {
    id: 4,
    title: "Resources",
    link: "https://cyberhubs.eu/resources/",
    active: false,
  },
  {
    id: 5,
    title: "Analytics",
    link: "/analytics",
    active: true,
  },
  {
    id: 6,
    title: "Contact us",
    link: "https://cyberhubs.eu/contact/",
    active: false,
  },
];
